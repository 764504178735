import Template from "./Login.html?raw";
import Component from "../../Utils/Component";
import {
  checkUsername,
  checkEmail,
  checkPassword,
  checkConfirmPassword,
  debounce,
  togglePass,
  unAuth,
  signUp,
} from "../../Functions/Validate";
import { Decode } from "../../Functions/Decoder";
import Endpoints from "../../Services/Endpoints";
import API from "../../Services/Api";
import Metaverse from "../../Metaverse";
import Icons from "../../Data/Icons.json";

import { Cookies } from "../../SceneManager";

class Login {
  constructor() {
    this.interceptor();
  }

  // Comprueba si hay una cookie con el token y si no la hay, muestra el formulario de login
  interceptor = () => {
    const token = Cookies.getAuthCookie();
    // Verificar si se ha unido el token completo
    token.length > 0 ? this.save(token) : this.set();
  };

  set = () => {
    new Component(Template, document.body, "auth");
    this.component = document.querySelector("#auth");
    google.accounts.id.initialize({
      client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
      callback: this.googlesign,
    });
    const parent = this.component.querySelector("#google_btn");
    google.accounts.id.renderButton(parent, { theme: "dark", width: 320 });

    const signinform = this.component.querySelector("form[name='signin']");
    const signupform = this.component.querySelector("form[name='signup']");
    let toggles = this.component.querySelectorAll(".toggle");

    signinform.addEventListener(
      "input",
      debounce((e) => this.validate(e))
    );
    signupform.addEventListener(
      "input",
      debounce((e) => this.validate(e))
    );
    signinform.addEventListener("submit", this.signin);
    signupform.addEventListener("submit", this.signup);

    toggles.forEach((element) => {
      let parent = element.parentElement;
      let field = parent.querySelector("input");
      element.addEventListener("click", () => togglePass(element, field));
    });

    //Imagen del logo
    const imgs = this.component.querySelectorAll(".logo-container-img");

    imgs.forEach((element) => {
      element.src = `${Endpoints.PATHS.UI.MAIN}${Icons.login.logoUEFA}.svg`;
    });

    //Imagen del fondo
    // this.component.querySelector(
    //   ".login-fondo"
    // ).src = `${Endpoints.PATHS.UI.MAIN}${Icons.login.fondo}.jpg`;
  };

  validate = (e) => {
    e.preventDefault();
    let compare;
    if (e.target.name === "confirm") {
      let div = e.target.parentElement;
      let previous = div.previousElementSibling;
      compare = previous.querySelector("input");
    }
    switch (e.target.name) {
      case "user":
        checkUsername(e.target);
        break;
      case "email":
        checkEmail(e.target);
        break;
      case "pass":
        checkPassword(e.target);
        break;
      case "confirm":
        checkConfirmPassword(compare, e.target);
        break;
    }
  };

  signin = (e) => {
    e.preventDefault();
    const target = e.target;
    const fields = target.elements;
    const email = fields.email;
    const pass = fields.pass;
    if (checkEmail(email)) {
      if (checkPassword(pass)) {
        API.metaverse({
          url: Endpoints.API.AUTH.SIGNIN,
          method: "POST",
          body: JSON.stringify({
            email: email.value,
            password: pass.value,
          }),
        })
          .then((res) => {
            res && res.status === 200 ? this.save(res.body) : unAuth(target);
            // switch (res.status) {
            //     case 401:
            //         unAuth(target)
            //         break

            //     default:
            //         break
            // }
          })
          .catch((error) => unAuth(target));
      }
    }
  };

  signup = (e) => {
    e.preventDefault();
    const target = e.target;
    const fields = target.elements;
    const user = fields.user;
    const email = fields.email;
    const pass = fields.pass;
    const confirm = fields.confirm;

    if (checkUsername(user)) {
      if (checkEmail(email)) {
        if (checkPassword(pass)) {
          if (checkConfirmPassword(pass, confirm)) {
            API.metaverse({
              url: Endpoints.API.AUTH.SIGNUP,
              method: "POST",
              body: JSON.stringify({
                userName: user.value,
                email: email.value,
                password: pass.value,
                repeatPassword: confirm.value,
              }),
            })
              .then((res) => {
                res && res.status === 200 ? signUp(target) : unAuth(target);
              })
              .catch((error) => unAuth(target));
          }
        }
      }
    }
  };

  send = async (data) => {
    return API.metaverse({
      url: Endpoints.API.AUTH.GOOGLE,
      method: "POST",
      body: JSON.stringify(data),
    });
  };

  googlesign = (e) => {
    const decoded = Decode(e.credential);
    if (decoded) {
      let user = "";
      if (decoded.given_name) {
        user = decoded.given_name.split(" ")[0];
      }
      let data = {
        userName: user,
        email: decoded.email,
        sub: decoded.sub,
      };
      this.send(data).then((res) => {
        if (res.status === 200) {
          if (res.body.hasOwnProperty("needValidation")) {
            this.send(data).then((res) => {
              if (res.status === 200) {
                this.save(res.body);
              }
            });
          } else {
            this.save(res.body);
          }
        }
      });
    }
  };

  save = (token) => {
    Cookies.setAuthCookie(token);
    if (this.component) {
      this.component.remove();
    }
    this.meta();
  };

  meta = () => {
    new Metaverse();
  };
}
export default new Login();
