import Endpoints from "./Services/Endpoints";
export default [
  {
    name: `environmentMapTexture`,
    room: `all`,
    type: `cubeTexture`,
    path: [
      `assets/media/skyBox/sky4/0_left_px.jpg`,
      `assets/media/skyBox/sky4/1_right_nx.jpg`,
      `assets/media/skyBox/sky4/2_up_py.jpg`,
      `assets/media/skyBox/sky4/3_down_ny.jpg`,
      `assets/media/skyBox/sky4/4_back_pz.jpg`,
      `assets/media/skyBox/sky4/5_front_nz.jpg`,
    ],
  },

  // Uefa Room
  
  {
    name: `uefaRoom`,
    room: `uefaRoom`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}00_uefaRoom/uefaRoom.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `navmeshUefaRoom`,
    room: `uefaRoom`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}00_uefaRoom/navmeshUefaRoom.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },

  // Sala Adidas
  {
    name: `salaAdidas`,
    room: `salaAdidas`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}04_salaAdidas/salaAdidas.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `balonesSalaAdidas`,
    room: `salaAdidas`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}04_salaAdidas/balonesSalaAdidas.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `navmeshSalaAdidas`,
    room: `salaAdidas`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}04_salaAdidas/navmeshSalaAdidas.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },

  // Sala UefaTV
  {
    name: `auditorioUefaTV`,
    room: `uefaTV`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}05_uefaTV/auditorioUefaTV.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `navmeshUefaTV`,
    room: `uefaTV`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}05_uefaTV/navmeshUefaTV.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },

  // Germany Team Hall
  {
    name: `germanyTeamHall`,
    room: `germany-teamHall`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}01_teamHall/germanyTeamHall.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `pantallasGermanyTeamHall`,
    room: `germany-teamHall`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}01_teamHall/pantallasGermanyTeamHall.glb`],
    receiveShadow: false,
    castShadow: false,
    raycaster: true,
  },
  {
    name: `navmeshGermanyTeamHall.glb`,
    room: `germany-teamHall`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}01_teamHall/navmeshGermanyTeamHall.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },

  // Locker Room
  {
    name: `germanyLockerRoom`,
    room: `germany-lockerRoom`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}02_lockerRoom/vestuarioAlemania.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `navmeshGermanyLockerRoom`,
    room: `germany-lockerRoom`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}02_lockerRoom/navmeshVestuarioAlemania.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },

  // Area Fans
  {
    name: `germanyAreaFan`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/germanyAreaFan.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `posterIzq1SalaFan`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/posterIzq1SalaFan.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `posterIzq2SalaFan`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/posterIzq2SalaFan.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
    invisible: true,
  },
  {
    name: `posterDer1SalaFan`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/posterDer1SalaFan.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `posterDer2SalaFan`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/posterDer2SalaFan.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
    invisible: true,
  },
  {
    name: `editables1SueloSalaFan`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/editables1SueloSalaFan.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `editables2SueloSalaFan`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/editables2SueloSalaFan.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `editables3SueloSalaFan`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/editables3SueloSalaFan.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `pantalla`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/pantalla.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `copaEurocopa`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/copaEurocopa.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `stadium_berlin`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/stadiums/stadium_berlin.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `stadium_munich`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/stadiums/stadium_munich.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `stadium_gelsenkirchen`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/stadiums/stadium_gelsenkirchen.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `stadium_dusseldorf`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/stadiums/stadium_dusseldorf.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `stadium_dortmund`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/stadiums/stadium_dortmund.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  {
    name: `germanynavmeshAreaFan`,
    room: `germany-areaFan`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.SALAS}03_areaFans/germanyNavmeshAreaFan.glb`],
    receiveShadow: true,
    castShadow: true,
    raycaster: true,
  },
  // StadiumMunich
  {
    name: `stadiumMunich`,
    room: `stadiumMunich`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.STADIUM}/stadiumMunich.glb`],
    receiveShadow: true,
    castShadow: true,
  },
  {
    name: `navmeshStadiumMunich`,
    room: `stadiumMunich`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.STADIUM}/navmeshStadiumMunich.glb`],
    receiveShadow: true,
    castShadow: true,
  },
  // StadiumDormunt
  {
    name: `stadiumDortmund`,
    room: `stadiumDortmund`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.STADIUM}/stadiumDortmund.glb`],
    receiveShadow: true,
    castShadow: true,
  },
  ,
  {
    name: `navmeshStadiumDortmund`,
    room: `stadiumDortmund`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.STADIUM}/navmeshStadiumDortmund.glb`],
    receiveShadow: true,
    castShadow: true,
  },
  // StadiumGelsenkirchen
  {
    name: `stadiumGelsenkirchen`,
    room: `stadiumGelsenkirchen`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.STADIUM}/stadiumGelsenkirchen.glb`],
    receiveShadow: true,
    castShadow: true,
  },
  {
    name: `navmeshStadiumGelsenkirchen`,
    room: `stadiumGelsenkirchen`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.STADIUM}/navmeshStadiumGelsenkirchen.glb`],
    receiveShadow: true,
    castShadow: true,
  },
  // StadiumBerlin
  {
    name: `stadiumBerlin`,
    room: `stadiumBerlin`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.STADIUM}/stadiumBerlin.glb`],
    receiveShadow: true,
    castShadow: true,
  },
  {
    name: `navmeshStadiumBerlin`,
    room: `stadiumBerlin`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.STADIUM}/navmeshStadiumBerlin.glb`],
    receiveShadow: true,
    castShadow: true,
  },
  // StadiumDusseldorf
  {
    name: `stadiumDusseldorf`,
    room: `stadiumDusseldorf`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.STADIUM}/stadiumDusseldorf.glb`],
    receiveShadow: true,
    castShadow: true,
  },
  {
    name: `navmeshStadiumDusseldorf`,
    room: `stadiumDusseldorf`,
    type: `gltfModel`,
    path: [`${Endpoints.LOCAL_PATHS.STADIUM}/navmeshStadiumDusseldorf.glb`],
    receiveShadow: true,
    castShadow: true,
  },
];
