const BASE = (path) => `${import.meta.env.VITE_DOMAIN}/metaverse/api/${path}`;
const BASE_AWS = (path, type) =>
  `${import.meta.env.VITE_AWS}/${import.meta.env.VITE_ENVIRONMENT}.${path}/${
    import.meta.env.VITE_METAVERSO
  }/${type}`;
const BASE_LOCAL = (path, type) => `assets/${path}/${type}`;

class Endpoints {
  static API = {
    AUTH: {
      ACCESS: `${BASE("project")}/access`,
      SIGNUP: `${BASE("auth")}/singup`,
      SIGNIN: `${BASE("auth")}/singin`,
      GOOGLE: `${BASE("auth")}/sign-google`,
    },
    REST: {
      AVATAR: `${BASE("avatar")}`,
      SHOP: `${BASE("avatar/shop")}`,
      USER: `${BASE("meta-user")}`,
      CONFIG: `${BASE("avatar/configurator")}`,
      ROOM: `${BASE("room/configurator")}`,
      COINS: `${BASE("coins/")}`,
      WALLET: `${BASE("wallet")}`,
      PURCHASE: {
        AVATAR: `${BASE("meta-user/avatar/purchase")}`,
        ROOM: `${BASE("meta-user/room/purchase")}`,
      },
      BANREQUEST: `${BASE("ban-request")}`,
      LASTPURCHASE: {
        AVATAR: `${BASE("meta-user/getLatestPurchasesAvatar")}`,
      },
      RESET: `${BASE("project/userActivity")}`,
    },
    SOCKET: {
      IO: import.meta.env.VITE_METAVERSE_SOCKET,
    },
  };
  static PATHS = {
    AVATAR: {
      MODEL: `${BASE_AWS("metaverse.avatar.configurator", "avatares/glb")}/`,
    },
    UI: {
      MAIN: `${BASE_AWS("metaverse.avatar.configurator", "ui")}/mainUI/`,
      CONFIG: `${BASE_AWS(
        "metaverse.avatar.configurator",
        "ui"
      )}/avatarConfig/`,
      SETTINGS: `${BASE_AWS("metaverse.avatar.configurator", "ui")}/options/`,
      COLLECTION: `${BASE_AWS(
        "metaverse.avatar.configurator",
        "ui"
      )}/collection/`,
      COLLECTION2: `${BASE_AWS(
        "metaverse.avatar.configurator",
        "coleccion"
      )}/glb/`,
    },
    RESOURCES: {
      ROOMS: `${BASE_AWS("metaverse.avatar.configurator", "salas")}/glb`,
    },
  };
  static LOCAL_PATHS = {
    AVATAR: {
      MODEL: `${BASE_LOCAL("assets_3D", "character")}/avatarConfigBD/glb/`,
    },
    STADIUM: `${BASE_LOCAL("assets_3D", "06_stadiums")}`,
    SALAS: `${BASE_LOCAL("assets_3D", "")}`,
    UI: {
      ICONS: `${BASE_LOCAL("media", "ui")}/mainUI/`,
      CONFIG: `${BASE_LOCAL("media", "ui")}/avatarConfig/`,
    },
  };
  static SETTINGS = {
    COOKIE_ID: import.meta.env.VITE_COOKIE_ID,
  };
}

export default Endpoints;
