import * as THREE from 'three'


class DegreesRotation{
    constructor(mesh, rotationX, rotationY, rotationZ){
        // declare angles
        this.anglex = rotationX;
        this.angley = rotationY;
        this.anglez = rotationZ;
        // Debe ser un Object3D
        this.mesh = mesh
        // declare x and y axes
        this.axisx = new THREE.Vector3(1, 0, 0);
        this.axisy = new THREE.Vector3(0, 1, 0);
        this.axisz = new THREE.Vector3(0, 0, 1);

        // init quaternions that will rotate along each axis
        this.quatx = new THREE.Quaternion();
        this.quaty = new THREE.Quaternion();
        this.quatz = new THREE.Quaternion();

        // set quaternions from each axis (in radians)...
        this.quatx.setFromAxisAngle(this.axisx, THREE.MathUtils.degToRad(this.anglex));
        this.quaty.setFromAxisAngle(this.axisy, THREE.MathUtils.degToRad(this.angley));
        this.quatz.setFromAxisAngle(this.axisz, THREE.MathUtils.degToRad(this.anglez));

        // ...then multiply them to get final rotation
        this.quaty.multiply(this.quatx);
        this.quatz.multiply(this.quaty);

        // apply multiplied rotation to your mesh
        this.mesh.quaternion.copy(this.quatz);
    }
}
export{DegreesRotation}