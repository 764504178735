import Endpoints from "./Endpoints";

export default class CookiesService{
    constructor(){
        this.id = Endpoints.SETTINGS.COOKIE_ID
    }

    setAuthCookie(token,id){
        // id debe ser un string de 2-3 iniciales 'PL' 'EU' 'MMO'
        const tokenLength = token.length;
            
        const quarterLength = Math.floor(tokenLength / 4);
    
        const firstPart = token.slice(0, quarterLength);
        const secondPart = token.slice(quarterLength, quarterLength * 2);
        const thirdPart = token.slice(quarterLength * 2, quarterLength * 3);
        const fourthPart = token.slice(quarterLength * 3);
    
        const expirationTime = new Date();
        expirationTime.setTime(expirationTime.getTime() + 86400 * 1000); // Tiempo de expiración de la cookie en milisegundos
    
        const dotenv = window.location.hostname.split(`.`);
        // Verificar si la URL contiene "localhost"
        if (dotenv[0] === `localhost` || `192`) {
            // Si la URL contiene "localhost", crear las cookies sin httpOnly, Secure y SameSite
            document.cookie = `_Secure-3${this.id}PAT=${firstPart}; expires=${expirationTime.toUTCString()}; path=/;`;
            document.cookie = `_Secure-1${this.id}PSTA=${secondPart}; expires=${expirationTime.toUTCString()}; path=/;`;
            document.cookie = `_Secure-1${this.id}PAT=${thirdPart}; expires=${expirationTime.toUTCString()}; path=/;`;
            document.cookie = `_Secure-3${this.id}PSTA=${fourthPart}; expires=${expirationTime.toUTCString()}; path=/;`;
        } else {
            // Si no es "localhost", establecer las cookies con httpOnly, Secure y SameSite
            // const cookieAttributes = `HttpOnly; Secure; SameSite=Strict;`;
            document.cookie = `_Secure-3${this.id}PAT=${firstPart}; expires=${expirationTime.toUTCString()}; path=/; Secure; SameSite=Strict;`;
            document.cookie = `_Secure-1${this.id}PSTA=${secondPart}; expires=${expirationTime.toUTCString()}; path=/; Secure; SameSite=Strict;`;
            document.cookie = `_Secure-1${this.id}PAT=${thirdPart}; expires=${expirationTime.toUTCString()}; path=/; Secure; SameSite=Strict;`;
            document.cookie = `_Secure-3${this.id}PSTA=${fourthPart}; expires=${expirationTime.toUTCString()}; path=/; Secure; SameSite=Strict;`;
        }
    }

    getAuthCookie(){
        const tokenParts = {};
        
        // Obtener las cookies.
        const cookies = document.cookie.split(`;`);
    
        // Filtrar y almacenar las cookies que contienen las partes del token
        cookies.forEach(cookie => {
            const trimmedCookie = cookie.trim();
            if (trimmedCookie.startsWith(`_Secure-`)) {
                const cookieParts = trimmedCookie.split(`=`);
                const cookieName = cookieParts[0];
                const cookieValue = cookieParts[1];
                tokenParts[cookieName] = cookieValue;
            }
        });
    
        // Reconstruir el token completo en el orden específico
        const token = [
            tokenParts[`_Secure-3${this.id}PAT`],
            tokenParts[`_Secure-1${this.id}PSTA`],
            tokenParts[`_Secure-1${this.id}PAT`],
            tokenParts[`_Secure-3${this.id}PSTA`]
        ].join(``);

        return token;
    }

    deleteAuthCookie(){
        document.cookie = `_Secure-3${this.id}PAT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        document.cookie = `_Secure-1${this.id}PSTA=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        document.cookie = `_Secure-1${this.id}PAT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        document.cookie = `_Secure-3${this.id}PSTA=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
}