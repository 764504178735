import * as THREE from "three";
import { DegreesRotation } from "../../Utils/Math/Math";
import Data from "../../Data/Portals.json";
import API from "../../Services/Api";
import Endpoints from "../../Services/Endpoints";
import Coockies from "../../Services/Cookies";
import { Room, Scene, SetRoom } from "../../SceneManager";

export default class Portal {
  
  constructor(metaverse) {
    this.metaverse = metaverse;
    // this.room = this.metaverse.room;
    this.raycaster = this.metaverse.raycaster;
    //Texture loader for img
    this.loader = new THREE.TextureLoader();
    // Params
    this.params = {
      sphereRadius: 1,
      holeRadius: 0.6,
      borderThickness: 0.05,
    };

    API.metaverse({
      url: Endpoints.API.REST.USER,
      method: "GET",
      token: new Coockies().getAuthCookie(),
    }).then((res) => {
      if (res.status === 200) {
        const email = res.body.email;
        const splitEmail = email.split("@");
        const key = splitEmail[0];
        switch (key) {
          case "munich":
            this.defaultStadium = "stadiumMunich";
            break;
          case "berlin":
            this.defaultStadium = "stadiumBerlin";
            break;
          case "gelsenkirchen":
            this.defaultStadium = "stadiumGelsenkirchen";
            break;
          case "dortmund":
            this.defaultStadium = "stadiumDortmund";
            break;
          case "dusseldorf":
            this.defaultStadium = "stadiumDusseldorf";
            break;
          default:
            this.defaultStadium = "stadiumBerlin";
        }

        if (Data[Room]) {
          const data = Data[Room].filter(
            (element) => element.visible
          );
          data.forEach((element) => {
            this.init(element);
            if (element.id === "germanyTeamHall-germanyStadium") {
              this.teleport(
                this.defaultStadium,
                element.waypoint,
                element.waypointRot
              );
            } else {
              this.teleport(element.to, element.waypoint, element.waypointRot);
            }
          });
        }
      } else {
        this.defaultStadium = "stadiumBerlin";
        if (Data[Room]) {
          const data = Data[Room].filter(
            (element) => element.visible
          );
          data.forEach((element) => {
            this.init(element);
            if (element.id === "germanyTeamHall-germanyStadium") {
              this.teleport(
                this.defaultStadium,
                element.waypoint,
                element.waypointRot
              );
            } else {
              this.teleport(element.to, element.waypoint, element.waypointRot);
            }
          });
        }
      }
    });
  }

  init(element) {
    //Initialize the group
    const portal = new THREE.Group();
    portal.position.set(
      element.position.x,
      element.position.y,
      element.position.z
    );
    new DegreesRotation(
      portal,
      element.rotation.x,
      element.rotation.y,
      element.rotation.z
    );
    portal.scale.set(-1.1, 1.1, 1.1);
    // Shared material
    const halfSphereGeometry = new THREE.SphereGeometry(
      this.params.sphereRadius,
      32,
      32,
      Math.PI,
      Math.PI
    );

    const cloakTexture = this.loader.load("");
    const cloakMaterial = new THREE.MeshBasicMaterial({
      map: cloakTexture,
      side: THREE.FrontSide,
      colorWrite: false,
    }); // change colorWrite: true to see the cloak
    if (!element.active) sceneTexture = this.loader.load("");

    if (element.special) {
      switch (this.defaultStadium) {
        case "munich":
          element.path = "assets/media/05_portales/portalStadiumMunich.jpg";
          break;
        case "berlin":
          element.path = "assets/media/05_portales/portalStadiumBerlin.jpg";
          break;
        case "gelsenkirchen":
          element.path = "assets/media/05_portales/portalStadiumGelsenkirchen.jpg";
          break;
        case "dortmund":
          element.path = "assets/media/05_portales/portalStadiumDortmund.jpg";
          break;
        case "dusseldorf":
          element.path = "assets/media/05_portales/portalStadiumDusseldorf.jpg";
          break;
        default:
          element.path = "assets/media/05_portales/portalStadiumBerlin.jpg";
      }
    }

    const sceneTexture = this.loader.load(element.path);
    sceneTexture.offset.x = 0.25;
    sceneTexture.repeat.set(0.5, 1);

    this.innerSphere = new THREE.Mesh(
      halfSphereGeometry,
      new THREE.MeshBasicMaterial({
        map: sceneTexture,
        side: THREE.BackSide,
      })
    );
    const outerSphere = new THREE.Mesh(halfSphereGeometry, cloakMaterial);
    const holeMesh = new THREE.Mesh(
      new THREE.RingGeometry(
        this.params.holeRadius + this.params.borderThickness,
        this.params.sphereRadius * 1.01,
        32
      ),
      cloakMaterial
    );
    this.borderMesh = new THREE.Mesh(
      new THREE.RingGeometry(
        this.params.holeRadius,
        this.params.holeRadius + this.params.borderThickness,
        32,
        1
      ),
      new THREE.MeshBasicMaterial({ color: 0x13f2c9, side: THREE.FrontSide })
    );

    this.innerSphere.name = "this.innerSphere";
    outerSphere.name = "outerSphere";
    holeMesh.name = "holeMesh";
    this.borderMesh.name = "this.borderMesh";
    this.innerSphere.isPortal = true;

    portal.add(this.innerSphere);
    portal.add(outerSphere);
    portal.add(holeMesh);
    portal.add(this.borderMesh);
    Scene.add(portal);
  }
  teleport(room, waypoint, waypointRot) {
    this.raycaster.addEventToModel(this.innerSphere.uuid, {
      click: () => {
        SetRoom(room)
        this.metaverse.updateScene(waypointRot);
        this.metaverse.player.initPos(waypoint, waypointRot);
      },
    });
    this.raycaster.addEventToModel(this.borderMesh.uuid, {
      click: () => {
        SetRoom(room)
        this.metaverse.updateScene(waypointRot);
        this.metaverse.player.initPos(waypoint, waypointRot);
      },
    });
  }
}
export { Portal };
