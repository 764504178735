import * as THREE from "three";
import { SkinnedMesh } from "../../Utils/SkinnedMesh";
import Data from "../../Data/Npc.json";
import { Clock } from "three";
import { Scene, Room } from "../../SceneManager";

export default class NpcIns {
  constructor(resources) {
    
    this.resources = resources;
    // this.count = 100
    this.amount = 3;
    this.count = Math.pow(this.amount, 3);
    this.instacedModels = [];
    this.initMesh();
  }

  // Funcion para obtener una matriz4 con posicion, rotacion y escala de un data.json
  getNpcPosition = (matrix, data) => {
    const position = new THREE.Vector3();
    const quaternion = new THREE.Quaternion();
    const scale = new THREE.Vector3();

    // Apply position
    position.x = data.px;
    position.y = data.py;
    position.z = data.pz;

    // Apply scale
    scale.x = scale.y = scale.z = 1;

    // Apply rotation (from Degrees to Euler)
    quaternion.setFromEuler(
      new THREE.Euler(
        THREE.MathUtils.degToRad(data.rx),
        THREE.MathUtils.degToRad(data.ry),
        THREE.MathUtils.degToRad(data.rz),
        "XYZ"
      )
    );

    // Convert all data transform to a matrix4
    matrix.compose(position, quaternion, scale);
    return matrix;
  };

  getRandomIndex(array) {
    let index = Math.floor(Math.random() * array.length);
    if (index === array.length) index--;
    return index;
  }

  initMesh() {
    // Comprueba que en la room en la que se esta hay npcs que colocar
    if (Data.rooms[Room]) {
      // Se obtiene la lista entera de posiciones en esta room
      const posList = [...Data.rooms[Room]];
      // Se obtiene la lista entero de npcs a dibujar
      const npcTypeList = Data.npc;
      // Se carga de forma dinamica todos los modelos de los npcs
      npcTypeList.forEach((npc) => {
        this.resources.loaders.gltfLoader.load(`${npc.file}`, (object) => {
          const model = object.scene;
          // Se aislan datos necesarios para realizar las instancias
          const mesh = model.getObjectByProperty("type", "SkinnedMesh");

          const geometry = mesh.geometry;
          const material = mesh.material;
          const skeleton = mesh.skeleton;
          const bindMatrix = mesh.bindMatrix;
          // Se crea un elemento para cada tipo de NPC
          this[`npc_${npc.id}`] = mesh;
          // Se instancian los modelos de los npcs
          this[`mesh_${npc.id}`] = new SkinnedMesh(
            geometry,
            material,
            npc.count
          );
          // Setup de los huesos para cada tipo de NPC
          this[`mesh_${npc.id}`].bind(skeleton, bindMatrix);
          this[`mesh_${npc.id}`].patcher();
          this[`mesh_${npc.id}`].frustumCulled = false;
          // Se crea un mixer para cada tipo
          this[`mixer_${npc.id}`] = new THREE.AnimationMixer(model);
          this[`mixer_${npc.id}`].clipAction(object.animations[0]).play();

          // Se crea una matriz4 donde se almacenaran los cambios de transformacion
          this[`matrix_${npc.id}`] = new THREE.Matrix4();
          // En un bucle, por cada elemento instanciado, se modifican su posicion
          for (let i = 0; i < npc.count; i++) {
            if (posList.length === 0) return;
            // Primero obtenemos un indice random (de 0 al limite de nuestra array de posiciones)
            let randomIndex = this.getRandomIndex(posList);
            // Se obtiene la matriz4 de transformacion en funcion del indice random
            this.getNpcPosition(this[`matrix_${npc.id}`], posList[randomIndex]);
            // Se elimina dicho elemento de la array de posiciones para evitar duplicados
            posList.splice(randomIndex, 1);
            // Se aplican los cambios de transformacion a la instancia correspondiente
            this[`mesh_${npc.id}`].setMatrixAt(i, this[`matrix_${npc.id}`]);
          }
          // Se crea un grupo para cada tipo de npc que contiene la cantidad de npc de cada uno
          this[`group_${npc.id}`] = new THREE.Group();
          // Una vez realizados los cambios se procede a añadir las instancias a la escena
          Scene.add(this[`group_${npc.id}`]);
          // Se agrega el modelo al grupo
          this[`group_${npc.id}`].add(model);
          // Se agrega el mesh a cada grupo
          this[`group_${npc.id}`].add(this[`mesh_${npc.id}`]);

          // Extra para obtener el dato del coste de memoria de GPU de cada instancia
          const geometryByteLength = this.getGeometryByteLength(geometry);
          // console.log(
          //   "GPU memory " +
          //     this.formatBytes(this.count * 16 + geometryByteLength, 2)
          // );
        });
      });
    }
  }

  // Cantidad de GPU Memory que consume mi instancia
  getGeometryByteLength(geometry) {
    let total = 0;

    if (geometry.index) total += geometry.index.array.byteLength;

    for (const name in geometry.attributes) {
      total += geometry.attributes[name].array.byteLength;
    }

    return total;
  }

  // Transformacion de datos numericos a peso en Bytes, KB, MB
  formatBytes(bytes, decimals) {
    if (bytes === 0) return "0 bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["bytes", "KB", "MB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  update(time) {
    Data.npc.forEach((element) => {
      if (this[`mesh_${element.id}`]) {
        // Actualizar el mixer
        this[`mixer_${element.id}`].update(time);
        for (let i = 0; i < element.count; i++) {
          // Actualizar instancias de huesos
          this[`mesh_${element.id}`].setBonesAt(
            i,
            this[`npc_${element.id}`].skeleton
          );
        }
      }
    });
  }
}
