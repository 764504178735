import io from "socket.io-client"
import Endpoints from "./Endpoints";
import { Lang } from "../Functions/Device"

const socket = io(Endpoints.API.SOCKET.IO,{transports: ["websocket"]})

export default class SocketService {
    /* constructor(metaverse){
        this.metaverse = metaverse
        this.room = `${import.meta.env.VITE_METAVERSO}-${import.meta.env.VITE_ENVIRONMENT}-${this.metaverse.getRoom()}`
        this.idiomaCompleto = navigator.language || navigator.userLanguage
        this.idiomaCliente = this.idiomaCompleto.split('-')[0]

        this.set()
    } */
        constructor(room){
            this.room = `${import.meta.env.VITE_METAVERSO}-${import.meta.env.VITE_ENVIRONMENT}-${room}`
            this.idiomaCompleto = navigator.language || navigator.userLanguage
            this.idiomaCliente = this.idiomaCompleto.split('-')[0]
            this.set()
            this.IO = socket
        }

    set = () => {
        // socket.emit('join-room', {room: this.room})
        socket.emit('join-room', {room: this.room})
        socket.on("connect", () => {
            console.log('CONNECTED')
        })

        socket.on('room-joined', (room, id) => {
            // console.log(room,id)
            socket.emit('get-all-users-in-room');
        })

        socket.on('all-users-in-room', (users, id) => {
        })

        socket.on('user-disconnected', (id) => {
        })

        socket.on('server-send-message', ({who, msn, disconnected, lang}) => {
            if(!(lang === this.idiomaCliente)){
                let langSource = lang
                let langTarget = this.idiomaCliente
                socket.emit('translate-message', {who, msn, langSource, langTarget})
            } else {
                // this.metaverse.menu.chat.msnOut(who, msn)
            }
        })

        socket.on('server-translated-message',({ who, msn, langSource, langTarget, msnTranslated }, socketid) => {
            // this.metaverse.menu.chat.msnOut(who, msnTranslated)
        });
    }

    message = data => {
        const payload = {
            who: data.who,
            msn: data.msn,
            room: {room: this.room},
            lang: Lang()
        }
        socket.emit('client-send-message',payload)
    }

    reconnect(){
        socket.emit('user-wwwwdisconnected', socket.id);
        // this.room = `${import.meta.env.VITE_METAVERSO}-${import.meta.env.VITE_ENVIRONMENT}-${this.metaverse.getRoom()}`
        this.room = `${import.meta.env.VITE_METAVERSO}-${import.meta.env.VITE_ENVIRONMENT}-${this.room}`
        socket.emit('join-room', {room:this.room});
    }
}