import Template from "./Quiz.html?raw";
import * as THREE from "three";
import { CSS3DObject } from "three/addons/renderers/CSS3DRenderer.js";

export default class Quiz {
  constructor(data) {
    this.init(data);
  }

  randomize = (data) => {
    let random = Math.floor(Math.random() * data.length);
    return data[random];
  };

  generate = (questions) => {
    let question = this.randomize(questions);
    let filtered = questions.filter((obj) => obj.id !== question.id);
    return {
      question: question,
      questions: filtered,
    };
  };

  resetOptions = (buttons) => {
    buttons.forEach((element) => {
      element.classList.remove("correct-answer");
      element.classList.remove("incorrect-answer");
    });
  };

  init = (data) => {
    const {
      scene,
      dimensions,
      position,
      rotation,
      offsetHeight,
      quiz,
      player,
    } = data;
    const { width, height } = dimensions;
    const { px, py, pz } = position;

    this.object_ = new THREE.Object3D();
    this.object_.visible = false;
    this.object_.visible = true;

    // Random de preguntas
    let question = this.randomize(quiz);
    // Guardar preguntas restantes
    let questions = quiz.filter((obj) => obj.id !== question.id);

    // Contenedor para el HTML del Template
    const container = document.createElement("div");
    container.innerHTML = Template;
    container.style.width = `${width}px`;
    container.style.height = `${height}px`;

    // Aplicar textos a template
    const text = container.querySelector(".text");
    const opt1 = container.querySelector(".quiz-opt-1");
    const opt2 = container.querySelector(".quiz-opt-2");
    const opt3 = container.querySelector(".quiz-opt-3");
    text.textContent = question.question;
    text.setAttribute("data-id", question.id);
    opt1.textContent = question.options.one;
    opt2.textContent = question.options.two;
    opt3.textContent = question.options.three;

    //  Usamos la libreria CSS3DObject para crear una ventana para nuestro container
    let CSS3D = new CSS3DObject(container);
    this.object_.object = CSS3D;
    this.object_.add(CSS3D);

    // Creamos  mesh para nuestra "ventana"
    const geometry = this.createGeometry(width, height);
    const material = new THREE.MeshLambertMaterial({
      opacity: 0,
      blending: THREE.NoBlending,
      // side: THREE.DoubleSide,
      transparent: true,
    });

    const mesh = new THREE.Mesh(geometry, material);
    // Ajuste de parámetros del mesh
    mesh.name = "object_element";
    mesh.castShadow = true;
    mesh.receiveShadow = false;
    this.object_.add(mesh);

    // Ajustamos la posicion, rotacion y escala inicial del Object que contendra todo (ventana y container HTML)
    this.object_.position.set(px, py + offsetHeight, pz);
    this.object_.scale.set(0.015, 0.015, 0.015);
    this.object_.rotation.set(0, rotation, 0);

    // Agregar el objeto con contenedor CSS a la escena
    scene.add(this.object_);
    this.object_.visible = false;

    // Redimensionar objeto
    setTimeout(() => {
      this.updateSize({
        container: container,
        mesh: mesh,
      });
      this.updateVisibility(player);
    }, 10);

    this.visibility = true;

    // LISTENERS
    let buttons = container.querySelectorAll("button");

    buttons.forEach((element) => {
      element.addEventListener("click", (data) => {
        data.target.innerText === question.answer
          ? data.target.classList.add("correct-answer")
          : data.target.classList.add("incorrect-answer");
        // Deshabilitar click en botones
        this.disableButtons(buttons);
        // Reiniciar el listado de preguntas

        if (questions.length <= 1) questions = quiz;
        // Filtrar por última pregunta
        let id = text.getAttribute("data-id");
        questions = questions.filter((obj) => obj.id !== id);
        // Nueva pregunta
        let updated = this.generate(questions);

        setTimeout(() => {
          text.setAttribute("data-id", updated.question.id);
          text.textContent = updated.question.question;
          opt1.textContent = updated.question.options.one;
          opt2.textContent = updated.question.options.two;
          opt3.textContent = updated.question.options.three;
          question.answer = updated.question.answer;
          // Actualizar tamaño del mesh
          this.updateSize({
            container: container,
            mesh: mesh,
          });
          // Habilitar click en los botones
          this.enableButtons(buttons);
          // Limpiar estilos
          this.resetOptions(buttons);
        }, 2500);
      });
    });

    const closeButton = container.querySelector(".close-btn");

    closeButton.addEventListener("click", () => {
      this.visibility = false;
      this.object_.visible = false;
    });

    this.animator(player, position);

    // Logica para poder interactuar con el container HTML
    // y volver a recuperar los eventos del Canvas de ThreeJS
    let webgl = document.querySelector(".webgl");
    container.onmouseover = () => webgl.classList.add("no-pointer-events");
    container.onmouseout = () => webgl.classList.remove("no-pointer-events");
  };

  disableButtons = (buttons) => {
    buttons.forEach((element) => {
      element.setAttribute("disabled", true);
    });
  };

  enableButtons = (buttons) => {
    buttons.forEach((element) => {
      element.removeAttribute("disabled");
    });
  };

  //La función que controla la visibilidad del contenedor HTML según la
  //proximidad con el Player
  updateVisibility(player) {
    let proximity = 4;
    let distance = this.object_.position.distanceTo(player.position);
    if (distance < proximity) {
      this.object_.visible = true;
      if (!this.visibility) {
        this.object_.visible = false;
      }
    } else {
      this.object_.visible = false;
      this.visibility = true;
    }
  }

  // Funcion obenter Datos para el ShapeGeometry
  dataGeo(widthGeo, heightGeo) {
    const cornerRadius = 8;
    const x = -widthGeo / 2;
    const y = -heightGeo / 2;

    const roundedSquareShape = new THREE.Shape();
    roundedSquareShape.moveTo(x + cornerRadius, y);
    roundedSquareShape.lineTo(x + widthGeo - cornerRadius, y);
    roundedSquareShape.quadraticCurveTo(
      x + widthGeo,
      y,
      x + widthGeo,
      y + cornerRadius
    );
    roundedSquareShape.lineTo(x + widthGeo, y + heightGeo - cornerRadius);
    roundedSquareShape.quadraticCurveTo(
      x + widthGeo,
      y + heightGeo,
      x + widthGeo - cornerRadius,
      y + heightGeo
    );
    roundedSquareShape.lineTo(x + cornerRadius, y + heightGeo);
    roundedSquareShape.quadraticCurveTo(
      x,
      y + heightGeo,
      x,
      y + heightGeo - cornerRadius
    );
    roundedSquareShape.lineTo(x, y + cornerRadius);
    roundedSquareShape.quadraticCurveTo(x, y, x + cornerRadius, y);
    return roundedSquareShape;
  }

  // Funcion que crea un THREE.ShapeGeometry con los datos de la funcion dataGeo()
  createGeometry(widthGeo, heightGeo) {
    const shape = this.dataGeo(widthGeo, heightGeo);
    return new THREE.ShapeGeometry(shape);
  }

  // Funcion "animate"
  animator(player, position) {
    this.animate = (timestamp) => {
      this.elapsedTime = (timestamp - this.previousTimeStamp) / 1000;

      this.updateVisibility(player, position);
      // if (bubble.mesh.visible) {
      //   // this.updateSize(bubble);
      // }
      this.previousTimeStamp = timestamp;
      requestAnimationFrame(this.animate);
    };
    this.previousTimeStamp = performance.now();
    requestAnimationFrame(this.animate);
  }

  // Funcion para actualizar el tamaño del bocadillo (container HTML y ventana)
  updateSize(bubble) {
    let { container, mesh } = bubble;
    // Obtener el tamaño del contenedor #quiz
    let element = container.querySelector("#quiz");
    let computedStyles = window.getComputedStyle(element);

    // // Aplicar el ancho y alto del contenedor al container
    container.style.width = computedStyles.getPropertyValue("width");
    container.style.height = computedStyles.getPropertyValue("height");

    let widthString = computedStyles.getPropertyValue("width");
    let widthInteger = parseInt(widthString);

    let heightString = computedStyles.getPropertyValue("height");
    let heightInteger = parseInt(heightString);

    let geometry = this.createGeometry(widthInteger, heightInteger);
    mesh.geometry = geometry;
    // bubble.mesh.updateMatrix()
  }

  updatePlayer(player) {
    this.player = player;
  }
}
