import * as THREE from "three";
import Data from "./Data/Store.json";
import Detail from "./Components/Detail/Detail";
import { DegreesRotation } from "./Utils/Math/Math";
import Api from "./Services/Api";
import Endpoints from "./Services/Endpoints";
import Cookies from "./Services/Cookies";

export default class Store {
  constructor(metaverse) {
    this.metaverse = metaverse
    this.room = metaverse.room;
    this.resources = metaverse.resources;
    this.scene = metaverse.scene;
    this.raycaster = metaverse.raycaster;
    this.models = []; // Array para almacenar las raquetas cargadas
    this.assetTiendainstance = null;

    // Crear Shop/Detail
    this.shop = new Detail("shop", metaverse);
    this.shop.hide();

    this.createProducts();
  }

  createProducts() {
    // Obtenemos las raquetas de la tienda actual
    const tiendaAdidas = Data[this.room];
    if (!tiendaAdidas) return;

    const textureLoader = new THREE.TextureLoader();

    Api.metaverse({
      url: Endpoints.API.REST.PURCHASE.AVATAR,
      method: "GET",
      token: new Cookies().getAuthCookie(),
    }).then((res) => {
      const itemsComprados = res.body;

      tiendaAdidas.forEach((item) => {
        if (item.visible !== true) return; //omite los items no visibles y continua el procesamiento para las visibles
        this.resources.loaders.gltfLoader.load(item["glb-file"], (gltf) => {
          const model = gltf.scene;
          // console.log(model);
          model.position.set(...item.position.split(",").map(Number));
          new DegreesRotation(
            model,
            item.rotation.x,
            item.rotation.y,
            item.rotation.z
          );
          model.data = item;
          this.scene.add(model);

          if (itemsComprados.find((i) => i.id == item.id_f)) {
            console.log('1');
            model.traverse((c) => {
              if (c.isMesh) {
                textureLoader.load(
                  "assets/media/store/compraRealizada.png",
                  (texture) => {
                    c.material.map = texture;
                    c.material.map.flipY = false;
                  }
                );
              }
            });
          } else {
            console.log('2');
            model.traverse((child) => {
              if (child.isMesh) {
                textureLoader.load(
                  "assets/media/store/compraPendiente.png",
                  (texture) => {
                    child.material.map = texture;
                    child.material.map.flipY = false;
                    child.userData = item;
                    this.raycaster.addEventToModel(child.uuid, {
                      click: () => {
                        this.metaverse.menu.hide()
                        this.shop.shopScreen = model;
                        this.shop.set(model.data, child.uuid);
                      },
                    });
                  }
                );
              }
            });
          }
        });
      });
    });
  }

  delete() {
    let element = document.querySelector("#shop");
    element.remove();
    this.shop.delete();
  }
}
