import * as THREE from "three";
import Template from "./Audio.html?raw";
import Component from "../../../Utils/Component";
import Endpoints from "../../../Services/Endpoints";

class Audio {
  constructor() {
    this.set();
  }

  set = () => {
    // Método para inicializar el componente de detalle de elemento
    new Component(Template, document.body); // Crea un nuevo componente con el template proporcionado
    this.component = document.querySelector(".audio");
    this.component.querySelector(
      "#effects"
    ).src = `${Endpoints.PATHS.UI.SETTINGS}opt_logo.svg`;
    this.component.querySelector(
      "#music"
    ).src = `${Endpoints.PATHS.UI.SETTINGS}opt_logo.svg`;
    this.component.querySelector(
      "#icon-effects"
    ).src = `${Endpoints.PATHS.UI.SETTINGS}opt_logo.svg`;
    this.component.querySelector(
      "#icon-music"
    ).src = `${Endpoints.PATHS.UI.SETTINGS}opt_logo.svg`;

    let cancel = this.component.querySelector("#audioClose");
    cancel.addEventListener("click", this.hide);

    this.hide; // Inicialmente oculta el componente
  };

  hide = () => {
    this.component.style.display = "none"; // Hacer el componente invisible
    document.querySelector(".options").style.pointerEvents = "auto"; // Habilitar clics para el fondo
  };
  // Método para mostrar el componente
  show = () => {
    this.component.style.display = "block"; // Hace el componente visible
    document.querySelector(".options").style.pointerEvents = "none"; // Deshabilitar clics para el fondo
  };
}

export default Audio;
