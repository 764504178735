import * as THREE from "three";
import Environment from "./Prefabs/Environment";
import Npc from "./Prefabs/Npc";
import Coins from "./Prefabs/Coins";
import { Mesh } from "three";
import Portal from "./Prefabs/Portal";
import DataIframes from "../Data/Iframes.json";
import DataQuiz from "../Data/Quiz.json";
import Iframe from "../Utils/Iframe";
import Quiz from "../Components/Quiz/Quiz";
import { DegreesRotation } from "../Utils/Math/Math";
import TpHolo from "./Prefabs/TpHolo";
import Store from "../Store.js";
import Cookies from "../Services/Cookies";
import Api from "../Services/Api";
import Endpoints from "../Services/Endpoints";
import Loader from "../Components/Loader/Loader.js";
import { Scene, Room, Menu, SetRoom, Emitter } from "../SceneManager.js";

export default class World {
  constructor(metaverse) {
    this.metaverse = metaverse;
    this.resources = this.metaverse.resources;
    this.raycaster = this.metaverse.raycaster;
    this.coins = new Coins(
      this.resources,
      Scene,
      Room,
      this.metaverse.player,
      Menu
    );
    this.loader = new Loader();
    Emitter.on("ready", () => {
      // Setup
      this.setRoomModels(this.resources.items);
      this.setVideos();
      this.loader.destroy();
    });

    // Emitter.on("loading", (data) => {
    //   this.loader.update(Math.round(data.loaded));
    // });

    this.effectoPortal = this.resources.loaders.textureLoader.load(
      "assets/media/05_portales/efectoPortal.png"
    );
  }

  setRoomModels(modelsData) {
    this.itemsArray = Object.values(modelsData);
    this.itemsArray = this.itemsArray.filter((item) => {
      return !(item instanceof THREE.CubeTexture);
    });

    const superArray = [];

    this.itemsArray.forEach((element) => {
      Scene.add(element.scene);
      element.scene.traverse((c) => {
        if (c.name.includes("navmesh")) {
          c.material.transparent = true;
          c.material.opacity = 0;
        }
      });
    });

    const r = superArray.reduce((acc, curr) => {
      const name = curr.name((acc[name] = acc[name] || [])).push(curr);
      return acc;
    }, {});

    for (const mesh in r) {
      const lodTest = new THREE.LOD();
      lodTest.name = mesh;
      r[mesh].forEach((e) => {
        if (!e.userData.lodLevel)
          lodTest.addLevel(e, e.userData.lodLevel * this.lodDistance);
      });
      // Scene.add(lodTest);
    }

    const portal = new Portal(this.metaverse);

    this.setQuiz();

    switch (Room) {
      case "uefaRoom":
        this.environment = new Environment(this.metaverse, 2);

        //------> Links Highlights Spawn Hall <------//
        this.itemsArray.forEach((items) => {
          items.scene.traverse((e) => {
            if (e.isMesh) {
              const name = e.name;
              if (name.includes("interactivo_pantallas_berlin"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/euro2024/hosts/berlin/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantalla_champions"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/uefachampionsleague/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantalla_conferenceLeague"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/uefaeuropaconferenceleague/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantallas_cologne"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/euro2024/hosts/cologne/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantallas_dortmund"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/euro2024/hosts/dortmund/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantalla_draws"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/euro2024/draws/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantallas_dusseldorf"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/euro2024/hosts/dusseldorf/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantalla_europaLeague"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/uefaeuropaleague/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantalla_fixtures"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/euro2024/fixtures-results/#/d/2023-11-16",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantallas_frankfurt"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/euro2024/hosts/frankfurt/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantallas_gelsenkirchen"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/euro2024/hosts/gelsenkirchen/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantallas_hamburg"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/euro2024/hosts/hamburg/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantalla_history"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/uefaeuro/history/rankings/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantallas_leipzig"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/euro2024/hosts/leipzig/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantallas_munich"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/euro2024/hosts/munich/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantalla_news"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/euro2024/news/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantalla_store"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.shopuefa.com/en/?_s=bm-fi-psc-uefa-hpshopnav",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantallas_stuttgart"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/euro2024/hosts/stuttgart/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantalla_ticketing"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/euro2024/ticketing/",
                      "_blank"
                    );
                  },
                });
              if (name.includes("interactivo_pantalla_womenChamp"))
                this.raycaster.addEventToModel(e.uuid, {
                  click: () => {
                    window.open(
                      "https://www.uefa.com/womenschampionsleague/",
                      "_blank"
                    );
                  },
                });
            }
          });
        });

        //------> Links Transicion Salas <------//
        this.holoCentralImg = this.resources.loaders.textureLoader.load(
          "assets/media/00_uefaRoom/img/holoTeams.png"
        );
        this.holoCentral = new THREE.Mesh(
          new THREE.PlaneGeometry(5.33, 3.8),
          new THREE.MeshBasicMaterial({ map: this.holoCentralImg })
        );
        this.holoCentral.position.set(0, 2.25, -77);
        this.holoCentral.material.side = THREE.DoubleSide;
        this.holoCentral.material.transparent = true;
        Scene.add(this.holoCentral);

        this.gerLinkHoloCentral = new THREE.Mesh(
          new THREE.PlaneGeometry(0.5, 0.5),
          new THREE.MeshBasicMaterial()
        );
        this.gerLinkHoloCentral.position.set(-2.1225, 2.77, -76.95);
        this.gerLinkHoloCentral.material.visible = false;
        this.raycaster.addEventToModel(this.gerLinkHoloCentral.uuid, {
          click: () => {
            SetRoom("germany-teamHall")
            const waypoint = { x: 0, y: 0, z: 0.275 };
            const waypointRot = { x: 0, y: 0, z: 0 };
            this.metaverse.updateScene(waypointRot);
            this.metaverse.player.initPos(waypoint, waypointRot);
          },
        });
        Scene.add(this.gerLinkHoloCentral);
        break;
      case "salaAdidas":
        this.environment = new Environment(this.metaverse, 2);
        this.itemsArray.forEach((items) => {
          if (items.name.includes("balonesSalaAdidas")) {
            this.bolaMixer = [];
            items.scene.traverse((c) => {
              if (c.isMesh) {
                const randAnimDur = Math.floor(Math.random() * 3) + 2;

                const randPosY = Math.random() * (1 - 0.35) + 0.35;
                const randPosY2 = Math.random() * (1 - 0.35) + 0.35;
                const model = c;
                const mixer = new THREE.AnimationMixer(model);
                const rotClipAction = new THREE.AnimationClip(
                  "rotation",
                  randAnimDur,
                  [
                    new THREE.VectorKeyframeTrack(
                      ".rotation[y]",
                      [0, randAnimDur],
                      [0, Math.PI * 2]
                    ),
                  ]
                );
                const transClipAction = new THREE.AnimationClip(
                  "rotation",
                  randAnimDur,
                  [
                    new THREE.VectorKeyframeTrack(
                      ".position[y]",
                      [0, randAnimDur],
                      [randPosY, randPosY2]
                    ),
                  ]
                );
                const action = mixer.clipAction(rotClipAction).play();
                const action2 = mixer.clipAction(transClipAction).play();
                action2.setLoop(THREE.LoopPingPong);
                this.bolaMixer.push({ model, mixer });
                model.mixer = mixer;
                model.action = action;
                model.action2 = action2;
              }
            });
            this.setupAnimation();
          }
        });

        const eccomerceImg = new THREE.TextureLoader().load(
          "assets/media/05_salaAdidas/img/ecommerceAdidas.jpg"
        );
        const ecommerceLink = new THREE.Mesh(
          new THREE.PlaneGeometry(5.5, 3.05),
          new THREE.MeshStandardMaterial({ map: eccomerceImg })
        );
        this.raycaster.addEventToModel(ecommerceLink.uuid, {
          click: () => {
            window.open("https://www.adidas.com/us/shop", "_blank");
          },
        });
        ecommerceLink.position.set(11.35, 2.08, -13.22);
        ecommerceLink.rotateY(-Math.PI / 2);
        Scene.add(ecommerceLink);
        break;
      case "uefaTV":
        this.environment = new Environment(this.metaverse, 2);
        break;
      case "germany-teamHall":
        this.environment = new Environment(this.metaverse, 3);
        // Carrusel de Imagenes
        this.carruselImagenesTextures = [];
        for (let i = 1; i <= 21; i++) {
          const textura = new THREE.TextureLoader().load(
            `assets/media/01_teamHall/img/carteles_patrocinadores${i}.jpg`
          );
          this.carruselImagenesTextures.push(textura);
        }

        this.itemsArray.forEach((item) => {
          if (
            item.name === "pantallasGermanyTeamHall" &&
            Room === "germany-teamHall"
          ) {
            this.model = item.scene;
            this.model.traverse((obj) => {
              if (obj.isMesh) {
                // Clonar el material para que cada malla tenga su propio material
                obj.material = obj.material.clone();

                // Genera un timing aleatorio para cada malla
                const timing = Math.floor(Math.random() * (5000 - 3000) + 3000);

                // Genera un índice aleatorio inicial para cada malla
                let indiceAleatorio = Math.floor(
                  Math.random() * this.carruselImagenesTextures.length
                );
                obj.material.map =
                  this.carruselImagenesTextures[indiceAleatorio];
                obj.material.needsUpdate = true;

                // Crea una función para encapsular el setInterval y mantener el contexto de indiceAleatorio
                const updateTexture = (obj, indiceInicial, intervalTime) => {
                  let currentIndice = indiceInicial;
                  setInterval(() => {
                    currentIndice++;
                    if (currentIndice >= this.carruselImagenesTextures.length) {
                      currentIndice = 0;
                    }
                    obj.material.map =
                      this.carruselImagenesTextures[currentIndice];
                    obj.material.needsUpdate = true;
                  }, intervalTime);
                };

                // Llama a la función encapsulada para cada malla
                updateTexture(obj, indiceAleatorio, timing);
              }
            });
          }
        });

        break;
      case "germany-lockerRoom":
        this.environment = new Environment(this.metaverse, 1.5);
        break;
      case "germany-areaFan":
        this.environment = new Environment(this.metaverse, 1.5);
        this.editableIzqPosterSalaFan = [];
        this.editableDerPosterSalaFan = [];
        this.editableStadium = [];
        this.heatMapGroup = [];
        this.indexIzq = 0;
        this.indexDer = 0;
        this.defaultStadium;

        // Editables
        this.itemsArray.forEach((items) => {
          if (items.name.includes("stadium")) {
            items.scene.traverse((c) => {
              if (c.isMesh && c.name.includes("heatmap"))
                this.heatMapGroup.push(c);

              c.visible = false;
            });
          }

          if (items.name === "pantalla") {
            items.scene.position.set(-5.8, 0, -8.28);
            items.scene.rotateY(1.57);
            const pantallaHeatMapTexture = new THREE.TextureLoader().load(
              "assets/media/03_areaFans/img/heatmapUEFA.jpg"
            );
            const pantallaHeatMapMesh = new THREE.Mesh(
              new THREE.PlaneGeometry(1.6, 0.9),
              new THREE.MeshBasicMaterial({ map: pantallaHeatMapTexture })
            );

            new DegreesRotation(pantallaHeatMapMesh, -20, 90, 0);
            pantallaHeatMapMesh.position.set(-5.8, 0.7, -8.28);
            pantallaHeatMapMesh.scale.set(0.875, 0.825);
            this.raycaster.addEventToModel(pantallaHeatMapMesh.uuid, {
              click: () => {
                this.heatMapGroup.forEach((e) => {
                  if (e.visible) e.visible = false;
                  else e.visible = true;
                });
              },
            });
            Scene.add(pantallaHeatMapMesh);
          }

          if (items.name.includes("poster") && items.name.includes("Izq"))
            this.editableIzqPosterSalaFan.push(items);
          else if (items.name.includes("poster") && items.name.includes("Der"))
            this.editableDerPosterSalaFan.push(items);
          if (items.name.includes("posterIzq2"))
            items.scene.traverse((c) => {
              if (c.isMesh) c.material.visible = false;
            });
          if (items.name.includes("posterDer2"))
            items.scene.traverse((c) => {
              if (c.isMesh) c.material.visible = false;
            });

          if (items.name === "editables1SueloSalaFan") {
            this.editables1SueloSalaFan = [];
            items.scene.traverse((child) => {
              if (child.isMesh) {
                this.editables1SueloSalaFan.push(child);
                child.material.visible = true;
              }
            });
          }
          if (items.name === "editables2SueloSalaFan") {
            this.editables2SueloSalaFan = [];
            items.scene.traverse((child) => {
              if (child.isMesh) {
                this.editables2SueloSalaFan.push(child);
                child.material.visible = false;
              }
            });
          }
          if (items.name === "editables3SueloSalaFan") {
            this.editables3SueloSalaFan = [];
            items.scene.traverse((child) => {
              if (child.isMesh) {
                this.editables3SueloSalaFan.push(child);
                child.material.visible = false;
              }
            });
          }

          if (items.name === "copaEurocopa") {
            const model = items.scene;
            model.position.set(-6, 2.25, -0.95);

            this.copaMixer = new THREE.AnimationMixer(model);

            const rotClipAction = new THREE.AnimationClip("rotation", 4, [
              new THREE.VectorKeyframeTrack(
                ".rotation[y]",
                [0, 4],
                [0, Math.PI * 2]
              ),
              new THREE.VectorKeyframeTrack(
                ".position[y]",
                [0, 2, 4], // Tiempos
                [2.25, 2.5, 2.25] // Valores: de 0 a 5 unidades arriba y luego de vuelta a 0
              ),
            ]);

            const action = this.copaMixer.clipAction(rotClipAction);
            action.play();
          }
        });

        // Maniquies
        Api.metaverse({
          url: Endpoints.API.REST.LASTPURCHASE.AVATAR,
          method: "GET",
          token: new Cookies().getAuthCookie(),
        }).then((res) => {
          if (res.body.length > 0) {
            const data = res.body[0].id;
            var key = data.split("_");
            key = key[1].slice(1);
            data &&
              key &&
              this.resources.loaders.gltfLoader.load(
                `assets/assets_3D/03_areaFans/maniquies/maniquies_${key}_areaFan.glb`,
                (gltf) => {
                  const model = gltf.scene;
                  Scene.add(model);
                }
              );
          } else {
            this.resources.loaders.gltfLoader.load(
              "assets/assets_3D/03_areaFans/maniquies/maniquies_default_areaFan.glb",
              (gltf) => {
                const model = gltf.scene;
                Scene.add(model);
              }
            );
          }
        });

        // Editables Stadium al inicio
        Api.metaverse({
          url: Endpoints.API.REST.USER,
          method: "GET",
          token: new Cookies().getAuthCookie(),
        }).then((res) => {
          if (res.status === 200) {
            const email = res.body.email;
            const splitEmail = email.split("@");
            const key = splitEmail[0];

            const defaultStadium = (key) => {
              this.itemsArray.forEach((e) => {
                if (e.name.includes("stadium")) {
                  if (e.name.includes(key)) {
                    this.defaultStadium = e;
                    e.scene.traverse((c) => {
                      c.visible = true;
                    });
                    return;
                  }
                  this.editableStadium.push(e);
                }
              });
            };
            switch (key) {
              case "munich":
                defaultStadium(key);
                this.editableStadium = [
                  this.defaultStadium,
                  ...this.editableStadium,
                ];
                break;
              case "berlin":
                defaultStadium(key);
                this.editableStadium = [
                  this.defaultStadium,
                  ...this.editableStadium,
                ];
                break;
              case "gelsenkirchen":
                defaultStadium(key);
                this.editableStadium = [
                  this.defaultStadium,
                  ...this.editableStadium,
                ];
                break;
              case "dortmund":
                defaultStadium(key);
                this.editableStadium = [
                  this.defaultStadium,
                  ...this.editableStadium,
                ];
              case "dusseldorf":
                defaultStadium(key);
                this.editableStadium = [
                  this.defaultStadium,
                  ...this.editableStadium,
                ];
                break;
              default:
                defaultStadium("berlin");
                this.editableStadium = [
                  this.defaultStadium,
                  ...this.editableStadium,
                ];
            }
          }
        });

        // Estadios Switch
        var stadiumIndex = 0;
        const editableStadiumLink = new THREE.Mesh(
          new THREE.SphereGeometry(0.25),
          new THREE.MeshStandardMaterial({ color: 0x00ba5d })
        );
        editableStadiumLink.position.set(-6.8, 1.35, -10);
        this.raycaster.addEventToModel(editableStadiumLink.uuid, {
          click: () => {
            this.editableStadium[stadiumIndex].scene.traverse((c) => {
              c.visible = false;
            });
            stadiumIndex++;
            if (stadiumIndex >= 5) stadiumIndex = 0;
            this.editableStadium[stadiumIndex].scene.traverse((c) => {
              c.visible = true;
            });
            console.log("cambio");
          },
        });
        Scene.add(editableStadiumLink);

        // Poster Switch
        this.editable1PosterLoungeLink = new THREE.Mesh(
          new THREE.SphereGeometry(0.25),
          new THREE.MeshStandardMaterial({ color: 0x00ba5d })
        );
        this.editable1PosterLoungeLink.position.set(-0.3, 1, -21.25);
        this.raycaster.addEventToModel(this.editable1PosterLoungeLink.uuid, {
          click: () => {
            this.editableIzqPosterSalaFan[this.indexIzq].scene.traverse(
              (child) => {
                if (child.isMesh) child.material.visible = true;
              }
            );
            this.indexIzq += 1;
            if (this.indexIzq >= 2) this.indexIzq = 0;
            this.editableIzqPosterSalaFan[this.indexIzq].scene.traverse(
              (child) => {
                if (child.isMesh) child.material.visible = false;
              }
            );
          },
        });
        Scene.add(this.editable1PosterLoungeLink);

        this.editable2PosterLoungeLink = new THREE.Mesh(
          new THREE.SphereGeometry(0.25),
          new THREE.MeshStandardMaterial({ color: 0x00ba5d })
        );
        this.editable2PosterLoungeLink.position.set(0.3, 1, -21.25);
        this.raycaster.addEventToModel(this.editable2PosterLoungeLink.uuid, {
          click: () => {
            this.editableDerPosterSalaFan[this.indexDer].scene.traverse(
              (child) => {
                if (child.isMesh) child.material.visible = true;
              }
            );
            this.indexDer += 1;
            if (this.indexDer >= 2) this.indexDer = 0;
            this.editableDerPosterSalaFan[this.indexDer].scene.traverse(
              (child) => {
                if (child.isMesh) child.material.visible = false;
              }
            );
          },
        });
        Scene.add(this.editable2PosterLoungeLink);

        // Suelo Switch
        this.editable1SueloLoungeLink = new THREE.Mesh(
          new THREE.SphereGeometry(0.25),
          new THREE.MeshStandardMaterial({ color: 0x00ba5d })
        );
        this.editable1SueloLoungeLink.position.set(-3.5, 0, -10);
        this.raycaster.addEventToModel(this.editable1SueloLoungeLink.uuid, {
          click: () => {
            this.editables1SueloSalaFan.forEach((child) => {
              if (child.isMesh) {
                child.material.visible = true;
              }
            });
            this.editables2SueloSalaFan.forEach((child) => {
              if (child.isMesh) {
                child.material.visible = false;
              }
            });
            this.editables3SueloSalaFan.forEach((child) => {
              if (child.isMesh) {
                child.material.visible = false;
              }
            });
          },
        });
        Scene.add(this.editable1SueloLoungeLink);

        this.editable2SueloLoungeLink = new THREE.Mesh(
          new THREE.SphereGeometry(0.25),
          new THREE.MeshStandardMaterial({ color: 0x00ba5d })
        );
        this.editable2SueloLoungeLink.position.set(-2.5, 0, -10);
        this.raycaster.addEventToModel(this.editable2SueloLoungeLink.uuid, {
          click: () => {
            this.editables1SueloSalaFan.forEach((child) => {
              if (child.isMesh) {
                child.material.visible = false;
              }
            });
            this.editables2SueloSalaFan.forEach((child) => {
              if (child.isMesh) {
                child.material.visible = true;
              }
            });
            this.editables3SueloSalaFan.forEach((child) => {
              if (child.isMesh) {
                child.material.visible = false;
              }
            });
          },
        });
        Scene.add(this.editable2SueloLoungeLink);

        this.editable3SueloLoungeLink = new THREE.Mesh(
          new THREE.SphereGeometry(0.25),
          new THREE.MeshStandardMaterial({ color: 0x00ba5d })
        );
        this.editable3SueloLoungeLink.position.set(-1.5, 0, -10);
        this.raycaster.addEventToModel(this.editable3SueloLoungeLink.uuid, {
          click: () => {
            this.editables1SueloSalaFan.forEach((child) => {
              if (child.isMesh) {
                child.material.visible = false;
              }
            });
            this.editables2SueloSalaFan.forEach((child) => {
              if (child.isMesh) {
                child.material.visible = false;
              }
            });
            this.editables3SueloSalaFan.forEach((child) => {
              if (child.isMesh) {
                child.material.visible = true;
              }
            });
          },
        });
        Scene.add(this.editable3SueloLoungeLink);
        this.setupAnimation();

        break;
      case "germany-stadium":
        this.environment = new Environment(this.metaverse, 2);
        break;
      case "stadiumMunich":
        this.environment = new Environment(this.metaverse, 2);
        new TpHolo(this.resources, Scene, this.raycaster, this.metaverse);

        break;
      case "stadiumDortmund":
        this.environment = new Environment(this.metaverse, 2);
        new TpHolo(this.resources, Scene, this.raycaster, this.metaverse);

        break;
      case "stadiumGelsenkirchen":
        this.environment = new Environment(this.metaverse, 3);

        new TpHolo(this.resources, Scene, this.raycaster, this.metaverse);
        break;
      case "stadiumBerlin":
        this.environment = new Environment(this.metaverse, 2);
        new TpHolo(this.resources, Scene, this.raycaster, this.metaverse);

        break;
      case "stadiumDusseldorf":
        this.environment = new Environment(this.metaverse, 2);
        new TpHolo(this.resources, Scene, this.raycaster, this.metaverse);

        break;
    }
  }

  setupAnimation() {
    this.animate = (timestamp) => {
      this.elapsedTime = (timestamp - this.previousTimeStamp) / 1000;
      if (this.mixer) this.mixer.update(this.elapsedTime);
      if (this.copaMixer) this.copaMixer.update(this.elapsedTime);
      if (this.bolaMixer)
        for (const model of this.bolaMixer) {
          if (model.mixer) model.mixer.update(this.elapsedTime);
        }
      this.previousTimeStamp = timestamp;
      this.worldBucle = requestAnimationFrame(this.animate);
    };
    this.previousTimeStamp = performance.now();
    this.worldBucle = requestAnimationFrame(this.animate);
  }

  dispose() {
    cancelAnimationFrame(this.worldBucle);
  }

  setVideos = () => {
    let data = DataIframes.find((element) => {
      return element.room === Room;
    });
    if (data && data.videos) {
      let iframes = data.videos.filter((frame) => frame.visible === true);
      if (iframes.length) {
        iframes.forEach((element) => {
          new Iframe(Scene).init(
            element.dimensions,
            element.position,
            element.rotation,
            element.data
          );
        });
      }
    }
  };

  setQuiz = () => {
    let config = DataQuiz.setup;
    let data = DataQuiz.rooms[Room];

    if (data) {
      this.resources.loaders.gltfLoader.load(
        `${config.path}${data.model}`,
        (glb) => {
          let model = glb.scene;
          let position = data.position;
          let rotation = data.rotation.ry;
          model.position.set(position.px, position.py, position.pz);
          model.rotateY(rotation);

          this.mixer = new THREE.AnimationMixer(model);
          let clip = this.mixer.clipAction(glb.animations[0]);
          clip.play();

          model.traverse((c) => {
            c.frustumCulled = false;
          });

          Scene.add(model);

          this.quiz = new Quiz({
            scene: Scene,
            dimensions: data.dimensions,
            position: position,
            rotation: rotation,
            offsetHeight: data.offsetHeight,
            quiz: data.questions,
            player: this.metaverse.player,
          });
        }
      );
    }
  };

  updater = (player) => {
    this.coins.updatePlayer(player);
    if (this.quiz) this.quiz.updatePlayer(player);
  };
}
