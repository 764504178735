import * as THREE from "three";
import Template from "./Options.html?raw";
import Component from "../../Utils/Component";
import Audio from "./Audio/Audio";
import Video from "./Video/Video";

class Options {
  constructor() {
    this.set();
    this.audio = new Audio();
    this.video = new Video();
  }

  set = () => {
    // Método para inicializar el componente de detalle de elemento
    new Component(Template, document.body); // Crea un nuevo componente con el template proporcionado
    this.component = document.querySelector(".options");

    let cancel = this.component.querySelector("#optionClose");
    cancel.addEventListener("click", this.hide);

    let sound = this.component.querySelector("#sound");
    sound.addEventListener("click", this.showAudio);

    let videoOptions = this.component.querySelector("#video");
    videoOptions.addEventListener("click", this.showVideo);
  };

  hide = () => {
    this.component.style.display = "none"; // Hacer el componente invisible
    document.querySelector(".settings").style.pointerEvents = "auto"; // Habilitar clics para el fondo
  };
  // Método para mostrar el componente
  show = () => {
    this.component.style.display = "block"; // Hace el componente visible
    document.querySelector(".settings").style.pointerEvents = "none"; // Deshabilitar clics para el fondo
  };

  showAudio = () => {
    this.audio.show();
  };

  showVideo = () => {
    this.video.show();
  };
}

export default Options;
