import * as THREE from "three";
import { DegreesRotation } from "../../Utils/Math/Math";
import Data from "../../Data/StadiumsTp.json";

export default class TpHolo {
  constructor(resource, scene, raycaster, metaverse) {
    this.metaverse = metaverse;
    const resources = resource;
    const scenes = scene;
    const raycast = raycaster;
    this.init(resources, scenes, raycast);
  }

  init(resources, scenes, raycast) {
    resources.loaders.textureLoader.load(
      "assets/media/06_stadiums/stadiums_tp_holo.png",
      (texture) => {
        texture.flipY = false;
        resources.loaders.gltfLoader.load(
          "assets/media/06_stadiums/referenciaHologramaStadiums.glb",
          (gltf) => {
            const model = gltf.scene;
            model.traverse((c) => {
              c.material = new THREE.MeshBasicMaterial({
                map: texture,
                transparent: true,
                opacity: 0.8,
                side: THREE.DoubleSide,
              });
            });
            scenes.add(model);
            this.addTP(scenes, raycast, "stadiumMunich");
            this.addTP(scenes, raycast, "stadiumDortmund");
            this.addTP(scenes, raycast, "stadiumGelsenkirchen");
            this.addTP(scenes, raycast, "stadiumBerlin");
            this.addTP(scenes, raycast, "stadiumDusseldorf");
          }
        );
      }
    );
  }
  addTP(scene, raycast, key) {
    if (this.metaverse.room == key) return;
    const data = Data[key];
    const mesh = new THREE.Mesh(
      new THREE.PlaneGeometry(),
      new THREE.MeshBasicMaterial({visible: false})
    );
    mesh.position.set(data.position.x, data.position.y, data.position.z);
    mesh.scale.set(0.53, 0.5);
    new DegreesRotation(mesh, 0, -45, 0);

    const waypointRot = { x: 0, y: 0, z: 0 };
    raycast.addEventToModel(mesh.uuid, {
      click: () => {
        this.metaverse.lastRoom = this.metaverse.room;
        this.metaverse.room = data.to;
        this.metaverse.updateScene(waypointRot);
      },
    });
    scene.add(mesh);
  }
}
