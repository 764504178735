const SetIcons = (nodes,source,path) => {
    nodes.forEach(element => {
        let data = element.getAttribute('data-icon')
        let name = source[data]
        let img = new Image()
        img.src = `${path}${name}.svg`
        img.alt = data
        element.appendChild(img)
    })
}

export {
  SetIcons
}