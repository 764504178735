import * as THREE from "three"
import { CSS3DObject } from 'three/addons/renderers/CSS3DRenderer.js';
import { DegreesRotation } from "./Math/Math";

export default class Iframe{
    constructor(scene){
        this.scene = scene
        this.webgl = document.querySelector('.webgl')
    }

    init = (dimensions, position, rotation, data) => {
        const { width, height } = dimensions
        const { px, py, pz } = position
        const { rx, ry, rz } = rotation

        const Object = new THREE.Object3D

        const container = document.createElement( 'div' );
        container.style.width = `${width}px`;
        container.style.height = `${height}px`

        const element = document.createElement( 'iframe' );
        element.style.width = `${width}px`;
        element.style.height = `${height}px`
        element.src = data
        element.setAttribute('allow','fullscreen')

        container.appendChild(element)
    
        let CSS3D = new CSS3DObject( container );
        Object.object = CSS3D
        Object.add(CSS3D)
        
        const geometry = new THREE.PlaneGeometry(
            width,
            height
        );
        const material = new THREE.MeshLambertMaterial({
            color: (0,0,0),
            // side : THREE.DoubleSide,
            opacity	: 0,
            blending: THREE.NoBlending,
            transparent : true
        });
        var mesh = new THREE.Mesh( geometry, material )
        mesh.castShadow = true
        mesh.receiveShadow = true
        mesh.name = 'object_element'
        
        Object.add( mesh )

        Object.position.set(px,py,pz)
        new DegreesRotation(Object,rx,ry,rz)
        Object.scale.set(.01,.01,.01)
        this.scene.add(Object)
        container.onmouseover = () => this.webgl.classList.add('no-pointer-events')
        container.onmouseout = () => this.webgl.classList.remove('no-pointer-events')
    }
}