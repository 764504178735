import * as THREE from "three";
import { Scene } from "../../SceneManager";

export default class Environment {
  constructor(metaverse, envMapIntensity) {
    this.metaverse = metaverse;
    this.resources = this.metaverse.resources;
    this.debug = this.metaverse.debug;
    this.envMapIntensity = envMapIntensity;

    this.setSunLight();
    this.setEnvironmentMap();

    // Debug Test -> Not Working Fine
    if (this.debug.active && !this.debug.guiCreated) {
      this.debugFolder = this.debug.ui.addFolder("Environment");
      this.sun = this.debugFolder.addFolder("Sun");

      this.debugFolder
        .add(this.directionaLight, "castShadow")
        .onChange((value) => {
          this.directionaLight.castShadow = value;
        });

      this.debugFolder
        .add(this.environmentMap, "intensity")
        .name("envMapIntensity")
        .min(0)
        .max(10)
        .step(0.001)
        .onChange((value) => {
          this.environmentMap.intensity = value;
          this.environmentMap.updateMaterial();
        });

      this.sun
        .add(this.directionaLight.shadow.camera, "left")
        .name("shadowCamLeft")
        .min(-500)
        .max(500)
        .step(0.001)
        .onChange((value) => {
          this.directionaLight.shadow.camera.left = value;
          this.updateShadowCamera();
        });

      this.sun
        .add(this.directionaLight.shadow.camera, "top")
        .name("shadowCamTop")
        .min(-500)
        .max(500)
        .step(0.001)
        .onChange((value) => {
          this.directionaLight.shadow.camera.top = value;
          this.updateShadowCamera();
        });

      this.sun
        .add(this.directionaLight.shadow.camera, "right")
        .name("shadowCamRight")
        .min(-500)
        .max(500)
        .step(0.001)
        .onChange((value) => {
          this.directionaLight.shadow.camera.right = value;
          this.updateShadowCamera();
        });

      this.sun
        .add(this.directionaLight.shadow.camera, "bottom")
        .name("shadowCamBottom")
        .min(-500)
        .max(500)
        .step(0.001)
        .onChange((value) => {
          this.directionaLight.shadow.camera.bottom = value;
          this.updateShadowCamera();
        });

      this.sun
        .add(this.directionaLight.position, "x")
        .name("sunLightX")
        .min(-500)
        .max(500)
        .step(0.001)
        .onChange((value) => {
          this.directionaLight.position.x = value;
        });

      this.sun
        .add(this.directionaLight.position, "y")
        .name("sunLightY")
        .min(-500)
        .max(500)
        .step(0.001)
        .onChange((value) => {
          this.directionaLight.position.y = value;
        });

      this.sun
        .add(this.directionaLight.position, "z")
        .name("sunLightZ")
        .min(-500)
        .max(500)
        .step(0.001)
        .onChange((value) => {
          this.directionaLight.position.z = value;
        });

      this.sun
        .add(this.directionaLight, "intensity")
        .name("sunIntensity")
        .min(0)
        .max(10)
        .step(0.001)
        .onChange((value) => {
          this.directionaLight.intensity = value;
        });

      this.sun
        .addColor(this.directionaLight, "color")
        .name("sunColor")
        .onChange((value) => {
          this.directionaLight.color.set(value);
        });

      this.debug.guiCreated = true;
    }
  }

  setSunLight() {
    // Directional Light (Sun)
    this.directionaLight = new THREE.DirectionalLight(0xfffbf1, 2.75);
    this.directionaLight.castShadow = true;
    this.directionaLight.shadow.mapSize.set(1024, 1024);
    this.directionaLight.shadow.camera.far = 1000;
    this.directionaLight.shadow.camera.left = -250;
    this.directionaLight.shadow.camera.top = 150;
    this.directionaLight.shadow.camera.right = 150;
    this.directionaLight.shadow.camera.bottom = -150;
    this.directionaLight.position.set(0, 135, 110);
    Scene.add(this.directionaLight);

    this.updateShadowCamera(); // Add this line to update the shadow camera's frustum
  }

  updateShadowCamera() {
    this.directionaLight.shadow.camera.updateProjectionMatrix();
  }

  setEnvironmentMap() {
    this.environmentMap = {};
    this.environmentMap.intensity = this.envMapIntensity;
    this.environmentMap.texture = this.resources.items.environmentMapTexture;
    Scene.background = this.environmentMap.texture;
    Scene.environment = this.environmentMap.texture;

    this.environmentMap.updateMaterial = () => {
      Scene.traverse((child) => {
        if (
          child instanceof THREE.Mesh &&
          child.material instanceof THREE.MeshStandardMaterial
        ) {
          child.material.envMap = this.environmentMap.texture;
          child.material.envMapIntensity = this.environmentMap.intensity;
          child.material.needsUpdate = true;
        }
      });
    };
    this.environmentMap.updateMaterial();
  }
}
