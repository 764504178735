import Template from "./Settings.html?raw";
import Component from "../../Utils/Component";
import Endpoints from "../../Services/Endpoints";
import API from "../../Services/Api";
import Options from "../Options/Options";
import DefaultData from '../../../metaverse.json'
import { Menu, Cookies } from "../../SceneManager";

class SettingsComponent {
  reset = Menu.reset
  constructor() {
    this.options = new Options();
    this.set();
  }

  set() {
    // Método para configurar la interfaz de usuario con los datos de la settings
    new Component(Template, document.body); // Crea un nuevo componente con el template proporcionado
    this.component = document.querySelector(".settings"); // Encuentra el componente principal en el DOM
    // logo desactivado de momento
    // this.component.querySelector(
    //   ".logo"
    // ).src = `${Endpoints.PATHS.UI.SETTINGS}opt_logo.svg`;

    //Agrega listener de eventos para botones de detalles y cierre
    let cancel = this.component.querySelector("#close1");
    cancel.addEventListener("click", this.hide);

    // let options = this.component.querySelector("#options");
    // options.addEventListener("click", this.showOptions);

    let reset = this.component.querySelector("#reset");
    reset.addEventListener("click", this.restart);

    let sesion = this.component.querySelector("#closeSesion");
    sesion.addEventListener("click", this.sesion);

    this.hide(); // Oculta inicialmente el componente
  }

  // Método para ocultar el componente
  hide = () => {
    this.component.classList.toggle("fade");
    this.component.style.visibility = "hidden";
    this.reset("settings");
    this.options.hide();
  };
  // Método para mostrar el componente
  show = () => {
    this.component.classList.remove("fade");
    this.component.style.visibility = "visible";
  };

  // showOptions = () => {
  //   this.options.show();
  // };

  showHelp = () => {
    this.hide();
  };

  sesion = () => {
    Cookies.deleteAuthCookie();
    location.reload();
  };

  restart = () => {
    const token = new Cookies().getAuthCookie();
    API.metaverse({
      url: Endpoints.API.REST.RESET,
      method: "DELETE",
      token: token,
    });

    API.metaverse({
      url: Endpoints.API.REST.AVATAR,
      method: "PUT",
      token: token,
      body: JSON.stringify(DefaultData.player)
    });

    API.metaverse({
      url: Endpoints.API.REST.WALLET,
      method: "GET",
      token: token,
    }).then((res) => {
      if (res && res.status === 200) {
        const actualMoney = res.body.wallet * -1;
        API.metaverse({
          url: Endpoints.API.REST.WALLET,
          method: "PUT",
          token: token,
          body: JSON.stringify({ wallet: actualMoney }),
        }).then((re) => {
          if (re && res.status === 200) {
            API.metaverse({
              url: Endpoints.API.REST.WALLET,
              method: "PUT",
              token: token,
              body: JSON.stringify({ wallet: 2000 }),
            }).then((r) => {
              if (r && r.status === 200) {
                setTimeout(() => {
                  location.reload();
                }, 500);
              }
            });
          }
        });
      } else {
        console.log(res);
      }
    });
  };
}

export default SettingsComponent;
