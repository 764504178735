import * as THREE from 'three'
import { OrbitControls } from './Utils/OrbitControls'
import Sizes from './Utils/Sizes'

export default  class OrbitalCamera extends THREE.Object3D {
    constructor (canvas) {
        super()
        this.camera = new THREE.PerspectiveCamera(60, Sizes.width / Sizes.heigth, 0.1, 300)
        this.camera.position.set(0, 2, 2)
        this.camera.lookAt(0, 2, 2)
        this.camRotationX = this.camera.rotation.x
        this.camPositionY = this.camera.position.y
        this.add(this.camera)
        this.orbitcam = this.camera.clone() 
        this.setOrbitControls(canvas)

    }
    setOrbitControls(canvas){
        this.orbit = new OrbitControls(this.orbitcam, canvas)
        this.orbit.target.set( 0, 1.65, 0)
        this.orbit.maxDistance = 10.0
        this.orbit.minDistance = 0.5
        this.orbit.minPolarAngle = Math.PI * .15
        this.orbit.maxPolarAngle =  Math.PI * .58
        this.orbit.enablePan = false
        this.orbit.zoomSpeed = 2.0
    }

    update (time, input) {
        let x,y = 0
        if(input.keys.u){x=1}
        if(input.keys.b){x=-1}
        if(input.keys.l){y=-1}
        if(input.keys.r){y=1}
        // x = Math.pow(x, 95)
        const cameraRotation = new THREE.Euler(x, y, 0)
        this.rotate(time, cameraRotation)
        this.orbit.update()
    }
    rotate (time, rotation) {
        if (rotation instanceof THREE.Euler) {
            const pitch = rotation.x 
            const yaw = this.rotation.y + rotation.y * -time
            this.rotation.set(this.rotation.x, yaw, this.rotation.z)
    
            const camAltitude = this.orbitcam.position.clone()
            camAltitude.setY(this.camPositionY + (rotation.x * 1.1))
            this.camera.position.lerp(camAltitude, .1)
            
            const camPitch = this.camera.quaternion.clone()
            this.camera.quaternion.slerp(camPitch, .1)

            if( rotation._x != 0){
                this.orbitcam.position.lerp(camAltitude, .1)
                this.orbitcam.quaternion.slerp(camPitch, .1)   
            }
        }
        this.camera.copy(this.orbitcam)
        this.camera.aspect = window.innerWidth / window.innerHeight
        this.camera.updateProjectionMatrix()
    }
    updater (player) {
        player.add(this)
    }
}