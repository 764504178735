import Data from '../../metaverse.json'

const interactive = type => {
    return Data.configurator.interactive[type]
}


const SetColor = (element,type,color) =>{
    color = color.split(',')
    element.traverse((mat)=>{
        if(mat.isMesh && mat.material.name.includes(interactive(type))){
            mat.material.color.set(color[0], color[1], color[2])
        }
    })
}

const SetShadow = (element) =>{
    element.traverse((mesh)=>{
        mesh.castShadow = true
        mesh.receiveShadow = true
        mesh.frustumCulled = false
    })
}

const StopVideos = () => {
    let element = document.querySelector('#css')
    let iframes = element.querySelectorAll( 'iframe');
    let videos = element.querySelectorAll( 'video' );
    iframes.forEach(iframe => {
        if ( iframe ) {
            let iframeSrc = iframe.src;
            iframe.src = iframeSrc;
        }
    });
    videos.forEach(video => {
        if ( video ) {
            video.stop();
        } 
    });
}

export {
  SetColor,
  SetShadow,
  StopVideos
}