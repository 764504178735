import * as THREE from "three";
import Sizes from "./Utils/Sizes";
import Player from "./Player";
import Keyboard from "./Utils/Keyboard";
import Renderer from "./Renderer";
import World from "./World/World";
import Resources from "./Utils/Resources";
import Debug from "./Utils/Debug";
import Joystick from "./Components/Joystick/Joystick";
import API from "./Services/Api";
import Endpoints from "./Services/Endpoints";
import Default from "./Data/Metaverse.json";
import { StopVideos } from "./Functions/Scene";
import NpcIns from "./World/Prefabs/NpcIns";
import NPCdata from "./Data/Npc.json";
import Store from "./Store";
import { 
  Room, 
  Socket, 
  Scene, 
  Cam, 
  Menu, 
  Emitter, 
  Cookies,
  SetUser,
  SetAvatar,
  Avatar,
  SetWallet,
  SetPurchased,
  InitComponents,
  Configurator,
  Raycast
} from "./SceneManager";

let instance = null;

export default class Metaverse {

  constructor() {
    // Singleton
    if (instance) {
      return instance;
    }
    instance = this;

    const token = Cookies.getAuthCookie();
    if (token.length > 0) {

      API.metaverse({
        url: Endpoints.API.REST.USER,
        method: "GET",
        token: token,
      }).then((res) => {
        if (res.status === 200) {
          if (res.body && res.body !== "") {
  
            // Setup datos de Usuario para Configurador, Estadios, Socket-MultiPlayer
            if(res.body._id&&res.body.username&&res.body.email){
              SetUser({
                id        : res.body._id,
                username  : res.body.userName,
                email     : res.body.email
              })
            }
            
            // Setup datos de Wallet para Shop, Configurador
            if(res.body.wallet){ SetWallet(res.body.wallet)}

            // Setup datos de Items comprados para Configurador, AreaFan
            if(res.body.avatarItems){ SetPurchased(res.body.avatarItems)}

            // Setup datos de avatar para Player y Configurador
            if(res.body.avatar){
              SetAvatar(res.body.avatar)
            }
            else{
              SetAvatar(Default.player)
              API.metaverse({
                url: Endpoints.API.REST.AVATAR,
                method: "POST",
                token: token,
                body: JSON.stringify(Default.player),
              }).then(() => {
                
              });
            }
            // Iniciar Avatar + Metaverso
            let data = Avatar[Avatar.gender]
            data.skin = Avatar.skin
            data.hair = Avatar.hair
            data.gender = Avatar.gender
            this.init(data)
 
          }
          else{this.endSession()}
        }
        else{this.endSession()}

      })
    }
  }

  endSession = () => {
    Cookies.deleteAuthCookie();
    location.reload();
  }

  init = avatar => {
    //Setup
    this.debug = new Debug();
    this.sizes = new Sizes();
    this.resources = new Resources();
    //Keyboard
    this.input = new Keyboard();
    //Player
    this.player = new Player(
      {
        player: {
          head: `${Endpoints.LOCAL_PATHS.AVATAR.MODEL}${avatar.head}.glb`,
          outfit: `${Endpoints.LOCAL_PATHS.AVATAR.MODEL}${avatar.outfit}.glb`,
          accessory: `${Endpoints.LOCAL_PATHS.AVATAR.MODEL}${avatar.accessory}.glb`,
          skin: avatar.skin,
          hair: avatar.hair,
          gender: avatar.gender,
        },
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Vector3(0, 0, 0),
      },
      false
    );
    this.player.add(Cam)
    
    this.renderer = new Renderer(this.sizes);
    this.raycaster = Raycast;

    this.world = new World(this);
    
    this.store = new Store(this);
    this.joystick = new Joystick();
    this.npcIns = new NpcIns(this.resources);

    InitComponents({chat:true, configurator:true, settings:true})

    this.sizes.on("resize", () => {
      this.resize();
    });
    this.startRender();
    Emitter.on("updatePlayer", (data) => {
      this.updatePlayer(data)
    });
  }

  resize() {
    this.renderer.resize();
  }
  startRender() {
    this.animate = (timestamp) => {
      this.elapsedTime = (timestamp - this.previousTimeStamp) / 1000;
      this.update(this.elapsedTime);
      this.renderer.instance.render(Scene, Cam.camera);
      this.renderer.css.render(Scene, Cam.camera);
      this.previousTimeStamp = timestamp;
      this.renderBluce = requestAnimationFrame(this.animate);
    };
    this.previousTimeStamp = performance.now();
    this.renderBluce = requestAnimationFrame(this.animate);
    // this.renderBluce()
  }
  update(time) {
    this.player.update(
      time,
      Cam,
      this.input,
      Menu.on,
      this.joystick
    );
    Cam.update(time, this.input);
    this.debug.update();
    if (this.npcIns) this.npcIns.update(time);
  }
  updateScene(waypointRot) {
    StopVideos();
    cancelAnimationFrame(this.renderBluce);
    Scene.traverse((c) => {
      if (c.geometry) c.geometry.dispose();
      if (c.material) {
        if (c.material.map) c.material.map.dispose();
        c.material.dispose();
      }
    });
    Scene.clear();
    
    this.resources = new Resources();
    this.renderer = new Renderer(this.sizes);
    
    this.world.dispose();
    this.world = new World(this);
    this.store.delete();
    this.store = new Store(this);
    if (NPCdata.rooms[Room])
      this.npcIns = new NpcIns(this.resources);
    Configurator.update(waypointRot);
    Socket.reconnect();
    this.startRender();
  }
  updatePlayer = (player) => {
    this.player = new Player(player, player.loaded);
    Cam.updater(this.player);
    this.world.updater(this.player);
  };
  updateChat = (data) => {
    this.menu.chat.update(data);
  };
  updateConfigurator = (data) => {
    this.menu.configurator.addItems(data);
  };
  getRoom() {
    return Room;
  }
}
