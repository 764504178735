const Touch = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

const Lang = () => {
  let language = navigator.language || navigator.userLanguage;
  return language.split("-")[0];
};

const Orientation = () => {
  if (window.matchMedia("(orientation: portrait)").matches) {
    return "portrait";
  }
  if (window.matchMedia("(orientation: landscape)").matches) {
    return "landscape";
  }
};

const Tablet = (element) => {
  let w = element.clientWidth;
  if (Touch() && w >= 810) {
    return true;
  }
  return false;
};

export { Touch, Lang, Orientation, Tablet };
