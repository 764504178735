import Template from "./Loader.html?raw";
import Component from "../../Utils/Component";

class Loader {
    constructor() {
        new Component(Template, document.body, 'loader')
        this.component = document.querySelector("#loader");
        // this.target = this.component.querySelector("span");
        // this.target.innerHTML = 0
    }

    update = percentage => {
        this.target.innerHTML = percentage
    }

    destroy = () => {
        setTimeout(() => {
            this.component.style.opacity = 0;
        }, 500);
        setTimeout(() => {
            this.component.remove()
        }, 1500);
    }
}
export default Loader;
