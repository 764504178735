import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import EventEmitter from './EventEmitter.js'
import sources from "../sources.js";
import { Room, Emitter } from '../SceneManager.js';

export default class Resources extends EventEmitter
{
    constructor()
    {
        super()
        // Setup
        this.items = {}
        this.toLoad = sources.length
        this.loaded = 0

        this.setLoaders()
        this.startLoading()
    }

    setLoaders()
    {
        this.loaders = {} 
        // Draco Loader
        this.loaders.dracoLoader = new DRACOLoader()
        this.loaders.dracoLoader.setDecoderPath('jsm/libs/draco/')
        // GLTF/GLB Loader
        this.loaders.gltfLoader = new GLTFLoader()
        this.loaders.gltfLoader.setDRACOLoader(this.loaders.dracoLoader)
        // OBJ Loader
        this.loaders.objLoader = new OBJLoader()
        // JPG/PNG Loader
        this.loaders.textureLoader = new THREE.TextureLoader()
        // Cube Env Map Loader
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader()
    }

    startLoading()
    {
        // Load each source 
        this.filteredSources = sources.filter((source)=> source.room === Room || source.room === 'all')
        this.toLoad = this.filteredSources.length
        
        for(const source of this.filteredSources)
        {
            if(source.type === 'gltfModel'){
                this.loaders.gltfLoader.load(
                    source.path,
                    (file)=>{       
                        file.name = source.name
                        file.bloom = source.bloom
                        if (source.link !==undefined) file.link = source.link
                        // file.link = 'demo'
                        file.scene.traverse((child)=>{
                            if(child instanceof THREE.Mesh){
                                child.material.name.includes('opacidad_') ? child.material.side = 2 : child.material.side = 0
                                source.castShadow !== undefined && (child.castShadow = source.castShadow);
                                source.receiveShadow !== undefined && (child.receiveShadow = source.receiveShadow);
                                if(source.raycaster !== undefined) child.raycaster = source.raycaster
                                // Add property bloom to child
                                if (source.bloom !==undefined) child.bloom = source.bloom
                            }
                        }) 
                        this.sourceLoaded(source, file);
                    },
                    (xhr) => {
                        if(!source.path[0].includes('navmesh')){
                            this.trigger('loading',[{loaded:(xhr.loaded / xhr.total) * 100}])
                        }
                    },
                )
            }
            else if(source.type === 'objModel'){
                this.loaders.objLoader.load(
                    source.path,
                    (file)=>{      
                        file.traverse((child)=>{
                            if(child instanceof THREE.Mesh){
                                source.castShadow !== undefined && (child.castShadow = source.castShadow);
                                source.receiveShadow !== undefined && (child.receiveShadow = source.receiveShadow);
                                if(source.raycaster !== undefined) child.raycaster = source.raycaster
                            }
                        }) 
                        this.sourceLoaded(source, file);
                    }
                )
            }
            
            else if(source.type === 'texture'){
                this.loaders.textureLoader.load(
                    source.path,
                    (file)=>{
                        this.sourceLoaded(source, file);
                    }
                )
            }

            else if(source.type === 'cubeTexture'){
                this.loaders.cubeTextureLoader.load(
                    source.path,
                    (file)=>{
                        this.sourceLoaded(source, file);
                    }
                )
            }
        }
    }

    sourceLoaded(source, file)
    {
        this.items[source.name] = file
        this.loaded++

        if(this.loaded === this.toLoad){
            Emitter.trigger('ready')
        }
    }
}