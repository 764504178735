import * as THREE from 'three'
import Cookies from "../../Services/Cookies";
import Endpoints from "../../Services/Endpoints";
import API from "../../Services/Api";
import Data from "../../Data/Metaverse.json";
import { CoinsTransform } from '../../Functions/Transform';

export default class Monedas{
    constructor(resources, scene, room, player, menu){
        this.resources = resources
        this.scene = scene
        this.room = room //Pasamos el valor de la sala actual
        this.player = player //Pasamos el valor del jugador actual
        this.menu = menu
        this.checkProximityInterval = 0.2; // Intervalo en segundos para la comprobación de proximidad
        this.timeSinceLastCheck = 0; // Tiempo transcurrido desde la última comprobación
        this.create()
        // Consultar posiciones a través de glb
        // this.positioner('assets/assets_3D/coinsPositions/...glb')   
    }
    create(){
        const token = new Cookies().getAuthCookie();
        this.token = token
        API.metaverse({
            url: Endpoints.API.REST.COINS+this.room.toLowerCase(),
            method: "GET",
            token: token,
          })
          .then((res) => {
            if (res.status !== 200) return;
            //DEMO
            // let coins = [
            //     {
            //         coordinates:'0,1,-1',
            //         value:5,
            //         _id:'coin'
            //     },
            //     {
            //         coordinates:'0,-0.5,-10',
            //         value:10,
            //         _id:'coinmed'
            //     }
            // ]
            let coins = res.body
            // console.log(coins);
            // Comprobamos si hay monedas en la sala
            if (coins && coins.length > 0) {

                const animationGroup = new THREE.AnimationObjectGroup();
                this.mixer = new THREE.AnimationMixer(animationGroup); // Creamos un mixer para la moneda
                const rotClipAction = new THREE.AnimationClip(
                "rotation",
                Data.coins.animation_time,
                [
                    // Creamos una animación de rotación para la moneda
                    new THREE.VectorKeyframeTrack(
                        ".rotation[y]",
                        [0, Data.coins.animation_time],
                        [0, Math.PI * 2]
                    ),
                ]
                );
                this.mixer.clipAction(rotClipAction).play();

                // Cargamos el modelo de la moneda
                this.resources.loaders.gltfLoader.load(Data.coins.model,(glb) => {
                    const model = glb.scene; // Obtenemos el modelo de la moneda
                    model.children[0].children[0].material.transparent = true;
                    model.children[0].children[0].material.opacity = Data.coins.opacity

                    // Se crea array para detectar monedas actuales en la escena
                    this.coins = []

                    coins.forEach(coin => {
                        const copy = model.clone();
                        const position = coin.coordinates.split(",");
                        copy.position.set(position[0], position[1], position[2]);
                        copy.name = coin._id;
                        copy.value = parseInt(coin.value);
                        
                        animationGroup.add(copy)
                        this.scene.add(copy)
                        // Se agrega referencia de la moneda al array
                        this.coins.push({id:coin._id})

                    });
                    this.animation()
                })


                /* // INSTANCES METHOD
                this.resources.loaders.gltfLoader.load(Data.coins.model,(gltf) => {
                    const model = gltf.scene // Obtenemos el modelo de la moneda
                    const geometry = model.children[0].children[0].geometry
                    const material = model.children[0].children[0].material
                    // Prueba
                    // const instancedGeometry = new THREE.InstancedBufferGeometry().copy(geometry);
                    this.mesh = new THREE.InstancedMesh( geometry, material, this.coins.length );
                    this.mesh.frustumCulled = false
                    this.scene.add(this.mesh)
                    this.dummy = new THREE.Object3D();
                    this.coins.forEach((coin,index) => {
                        // Intento de posicionamiento inicial
                        // const position = coin.coordinates.split(",")
                        // this.dummy.position.set(position[0], position[1], position[2]);
                        this.dummy.updateMatrix();
                        this.dummy.coin = coin.id
                        this.mesh.setMatrixAt( index, this.dummy.matrix );
                    });
                    const clip = new THREE.AnimationClip(
                        "rotation",
                        1.5,
                        [
                          new THREE.VectorKeyframeTrack(
                            ".rotation[y]",
                            [0, 1.5],
                            [0, Math.PI * 2]
                          ),
                        ]
                    );
                    this.mixer = new THREE.AnimationMixer(this.dummy);
                    this.mixer.clipAction(clip).play();
                    // LIMPIAR INTERVAL
                    const interval = setInterval(() => {
                        // this.checkProximity(this.mesh, token);
                      }, 250);
                    this.animation()
                }); */

            }
          })
    }

    checkProximity(coin) {
        // Este método comprobará la proximidad de cada moneda al jugador
        let distance = coin.position.distanceTo(this.player.position);
        if (distance < Data.coins.proximity) { // Umbral de proximidad
            this.pickCoin(coin.name, coin.value);
            let filtered = this.coins.filter(object=> object.id !== coin.name)
            this.coins = filtered // Elimina moneda de la lista
            this.scene.remove(coin); // Elimina la moneda de la escena
        }
    }

    pickCoin(id,value){
        this.menu.updateCoins(value);
        API.metaverse({
            url: Endpoints.API.REST.WALLET,
            method: "PUT",
            token: this.token,
            body: JSON.stringify({ wallet: value }),
          
        })
        .then((res) => {
            // console.log('OK WALLET');
            // console.log(res);
            API.metaverse({
                url: `${Endpoints.API.REST.COINS}${this.room.toLowerCase()}/taken/${id}`,
                method: "PUT",
                token: this.token,
            })
            .then((info) => {
                // console.log('OK COINS');
                // console.log(info);
            })
        })
    }

    
    animation() {
        this.animate = (timestamp) => {
            let elapsedTime = (timestamp - this.previousTimeStamp) / 1000; // Calculamos el tiempo transcurrido desde el último cuadro
            
            //Animación de las monedas
            if (this.mixer) this.mixer.update(elapsedTime)
            // Detectar proximidad de monedas faltantes ( Se reduce la carga de recursos al recoger monedas )
            if(this.coins && this.coins.length){
                this.coins.forEach((element) => {
                    let coin  = this.scene.getObjectByName(element.id)
                    if(coin) this.checkProximity(coin);
                })
            }
           
           /*  // INSTANCES METHOD
            if (this.mixer) {
                this.mixer.update(elapsedTime);
                this.coins.forEach((coin,index) => {    
                    const position = coin.coordinates.split(",")
                    this.dummy.position.set(position[0], position[1], position[2]);        
                    this.dummy.updateMatrix();
                    this.dummy.name = coin.id
                    this.mesh.setMatrixAt(  index , this.dummy.matrix )
                    this.checkProximity(this.dummy);
                })
                this.mesh.instanceMatrix.needsUpdate = true;
            } */
            this.previousTimeStamp = timestamp;
            requestAnimationFrame(this.animate);
        };
        this.previousTimeStamp = performance.now();
        requestAnimationFrame(this.animate);
    }

    updatePlayer(player){
        this.player = player
    }

    positioner(path){
        this.resources.loaders.gltfLoader.load(path,(glb) => {
            // console.log(path);
            CoinsTransform(glb.scene,1)
        })
    }
}
