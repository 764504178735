import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { StateController, Machine } from "./Utils/StateMachine";
import Data from "../metaverse.json";
import { SetShadow, SetColor } from "./Functions/Scene";
import { DegreesRotation } from "./Utils/Math/Math";
import { Scene, SetPlayerPosition, Emitter } from "./SceneManager"

export default class PlayerController extends THREE.Group {

  constructor(props, loaded) {
    super();
    this.init(props);
    this.position.add(props.position);
    SetPlayerPosition(this.position)
    
    Emitter.on("ready", () => {
      this.loadNavMesh();
    });
    loaded && this.loadNavMesh();
  }

  loadNavMesh = () => {

    let options = {
      fall: 0.5,
      height: 1,
    };
    
    if (this.itemsNavmesh) this.navmeshElement = this.itemsNavmesh.scene;
    this.fall = options.fall !== undefined ? options.fall : 0.5;
    this.height = options.height !== undefined ? options.height : 1.6;

    this.down = new THREE.Vector3(0, -1, 0);
    this.raycaster = new THREE.Raycaster();
    this.raycaster.far = 2;
    this.maxYVelocity = 0.5;
    this.gravity = -1;
    this.yVel = 0;
    this.result = [];
  };

  init = (props) => {
    const dracoLoader = new DRACOLoader();
    this.animations_head = {};
    this.animations_outfit = {};
    this.animations_accessory = {};
    this.stateMachine_head = new StateController(
      new Machine(this.animations_head)
    );
    this.stateMachine_outfit = new StateController(
      new Machine(this.animations_outfit)
    );
    this.stateMachine_accessory = new StateController(
      new Machine(this.animations_accessory)
    );
    dracoLoader.setDecoderPath("jsm/libs/draco/models/");
    const loader = new GLTFLoader();
    loader.setDRACOLoader(dracoLoader);

    let data = props.player;
    this.name = "meta-avatar";
    this.group = new THREE.Group();
    loader.load(data.head, (data_head) => {
      loader.load(data.outfit, (data_outfit) => {
        loader.load(data.accessory, (data_accessory) => {
          let head = data_head.scene;
          let outfit = data_accessory.scene;
          let accessory = data_outfit.scene;

          this.group.add(head);
          this.group.add(outfit);
          this.group.add(accessory);

          SetShadow(this.group);
          SetColor(this.group, "skin", props.player.skin);
          SetColor(this.group, "hair", props.player.hair);
          this.group.position.set(0, 0, 0);

          props.rotation &&
            new DegreesRotation(
              this.group,
              props.rotation.x,
              props.rotation.y + 180,
              props.rotation.z
            );

          this.add(this.group);

          this.mixer_head = new THREE.AnimationMixer(head);
          this.mixer_outfit = new THREE.AnimationMixer(outfit);
          this.mixer_accessory = new THREE.AnimationMixer(accessory);

          this.manager = new THREE.LoadingManager();
          this.manager.onLoad = () => {
            this.stateMachine_head.SetState("idle");
            this.stateMachine_outfit.SetState("idle");
            this.stateMachine_accessory.SetState("idle");
          };

          const loader = new GLTFLoader(this.manager);
          loader.setPath(
            `./assets/assets_3D/character/animations_${data.gender}/`
          );

          Data.animations.forEach((name) => {
            // loader.setDRACOLoader( dracoLoader )
            loader.load(`${name}.glb`, (subtarget) => {
              const clip = subtarget.animations[0];
              const action_head = this.mixer_head.clipAction(clip);
              const action_outfit = this.mixer_outfit.clipAction(clip);
              const action_accessory = this.mixer_accessory.clipAction(clip);
              this.animations_head[name] = { clip: clip, action: action_head };
              this.animations_outfit[name] = {
                clip: clip,
                action: action_outfit,
              };
              this.animations_accessory[name] = {
                clip: clip,
                action: action_accessory,
              };
            });
          });
        });
      });
    });
    Scene.add(this);
  };

  update = (time, camera, input, menu, joystick) => {
    if (!menu) {
      let joysticker = false;
      let x,
        z = 0;
      if (joystick.x !== 0 || joystick.y !== 0) {
        x = joystick.x;
        z = joystick.y;
        joysticker = true;
      } else {
        if (input.keys.forward) {
          z = -1;
        }
        if (input.keys.backward) {
          z = 1;
        }
        if (input.keys.left) {
          x = -1;
        }
        if (input.keys.right) {
          x = 1;
        }
        if (input.keys.u) {
          z = -1;
        }
        if (input.keys.b) {
          z = 1;
        }
        if (input.keys.l) {
          x = -1;
        }
        if (input.keys.r) {
          x = 1;
        }
      }
      this.stateMachine_head.Update(time, input, joysticker);
      this.stateMachine_outfit.Update(time, input, joysticker);
      this.stateMachine_accessory.Update(time, input, joysticker);
      const playerPosition = new THREE.Vector3(x, 0, z);
      input.keys.shift
        ? playerPosition.multiplyScalar(time * 15)
        : playerPosition.multiplyScalar(time * 8);
      playerPosition.applyEuler(camera.rotation);
      playerPosition.applyEuler(camera.orbit.object.rotation);
      this.move(playerPosition, time);
      if (this.mixer_head) this.mixer_head.update(time);
      if (this.mixer_outfit) this.mixer_outfit.update(time);
      if (this.mixer_accessory) this.mixer_accessory.update(time);
    } else {
      if (this.mixer_head) this.mixer_head.update(time);
      if (this.mixer_outfit) this.mixer_outfit.update(time);
      if (this.mixer_accessory) this.mixer_accessory.update(time);
    }
  };

  move = (movement, delta) => {
    // console.log(movement);
    if (!movement instanceof THREE.Vector3) return;
    if (movement.x === 0 && movement.z === 0) return;
    // if (!this.enabled) return;
    // if(this.navmeshElement === undefined || null) return
    this.raycastPosition = new THREE.Vector3(
      this.position.x,
      this.position.y + this.height,
      this.position.z
    );
    // console.log(this.position);
    this.raycaster.set(this.raycastPosition, this.down);

    this.result = this.raycaster.intersectObjects(Scene.children);
    this.x = this.result.find((item) => {
      return item.object.name.includes("navmesh");
    });
    this.currentPosition = this.position.clone();
    this.nextPosition = this.currentPosition
      .clone()
      .add({ x: movement.x, y: 0, z: movement.z });
    this.delta = delta;
    if (this.x) {
      this.angle =
        Math.PI +
        Math.atan2(
          this.position.x - this.nextPosition.x,
          this.position.z - this.nextPosition.z
        );
      this.position.copy(this.nextPosition);
      if (this.group)
        this.group.setRotationFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          this.angle
        );

      this.position.y = this.x.point.y;
      this.y = this.x.point;

      // Intento de imitacion del navmesh de Aframe de Polonia
      // this.y.y += this.height
      this.tempVec = new THREE.Vector3();
      if (this.nextPosition.y - (this.y.y - this.yVel * 2) > 0.01) {
        this.yVel = Math.max(
          this.gravity * this.delta * 0.001,
          -this.maxYVelocity
        );
        this.y.y = this.nextPosition.y + this.yVel;
      } else {
        this.yVel = 0;
      }
      this.tempVec.copy(this.y);
      this.parent.worldToLocal(this.tempVec);
      this.tempVec.sub(this.position);
      if (this.position) this.tempVec.y = this.position.y;
      this.position.clone().add(this.tempVec);

      Emitter.trigger("moving");
    } else {
      this.angle =
        Math.PI +
        Math.atan2(
          this.position.x - this.nextPosition.x,
          this.position.z - this.nextPosition.z
        );
      if (this.target)
        this.target.setRotationFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          this.angle
        ); //???
      if (this.y) this.position.copy(this.y);
      this.position.add({ x: movement.x, y: 0, z: movement.z });
    }
  };

  initPos(position, rotation) {
    this.position.set(position.x, position.y, position.z);
    new DegreesRotation(this.children[0], rotation.x, rotation.y, rotation.z);
  }

  getPosition() {
    return this.position;
  }
}
