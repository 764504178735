import Messages from "../Data/Validate.json"

const isRequired = value => value === '' ? false : true

const isEmailValid = (email) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
}

const isPasswordSecure = (password) => {
    // const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")
    const regex =  /^.{8,}$/
    return regex.test(password)
}

const showError = (input,message) => {
    const formField = input.parentElement
    const form = formField.parentElement
    const error_field = form.querySelector('small')
    formField.classList.remove('success')
    formField.classList.add('error')
    error_field.classList.add('error')
    error_field.textContent = message
    
}

const showSuccess = input => {
    const formField = input.parentElement
    const form = formField.parentElement
    const error_field = form.querySelector('small')
    formField.classList.remove('error')
    formField.classList.add('success')
    error_field.textContent = ''
}

const unAuth = target => {
    const fields = target.querySelectorAll('.form-field')
    fields.forEach(element => {
        element.classList.remove('success')
    })
    const error_field = target.querySelector('small')
    error_field.textContent = Messages.unauthorized
}

const signUp = target => {
    const error_field = target.querySelector('small')
    error_field.classList.remove('error')
    error_field.textContent = Messages.signup_success
}

const checkUsername = element => {
    let valid = false
    const user = element.value.trim()
    if (!isRequired(user)) {
        showError(element, Messages.username_required)
    } else {
        showSuccess(element)
        valid = true
    }
    return valid
}

const checkEmail = element => {
    let valid = false
    const email = element.value.trim()
    if (!isRequired(email)) {
        showError(element, Messages.email_required)
    } else if (!isEmailValid(email)) {
        showError(element,Messages.email_check)
    } else {
        showSuccess(element)
        valid = true
    }
    return valid
}


const checkPassword = element => {
    let valid = false
    const password = element.value.trim()
    if (!isRequired(password)) {
        showError(element, Messages.pass_required)
    } else if (!isPasswordSecure(password)) {
        showError(element, Messages.pass_secure)
    } else {
        showSuccess(element)
        valid = true
    }
    return valid
}

const checkConfirmPassword = (pass,confirm) => {
    let valid = false
    const confirmPassword = confirm.value.trim()
    const password = pass.value.trim()
    if (!isRequired(confirmPassword)) {
        showError(confirm, Messages.confirm_required)
    } else if (password !== confirmPassword) {
        showError(confirm, Messages.confirm_nomatch)
    } else {
        showSuccess(confirm)
        valid = true
    }

    return valid
}

const debounce = (fn, delay = 700) => {
    let timeoutId
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
            fn.apply(null, args)
        }, delay)
    }
}

const togglePass = (element,pass) => {
    const type = pass.getAttribute("type") === "password" ? "text" : "password"
    pass.setAttribute("type", type)
    element.classList.toggle("bi-eye")
}

export {
    checkUsername,
    checkEmail,
    checkPassword,
    checkConfirmPassword,
    debounce,
    togglePass,
    signUp,
    unAuth
}