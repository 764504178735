import * as THREE from "three";
import Template from "./Detail.html?raw";
import Component from "../../Utils/Component";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { SetIcons } from "../../Functions/Interface";
import { Touch, Orientation, Tablet } from "../../Functions/Device";
import Api from "../../Services/Api";
import Endpoints from "../../Services/Endpoints";
import Icons from "../../Data/Icons.json";
import Cookies from "../../Services/Cookies";
import { Emitter } from "../../SceneManager";

// Clase para mostrar los detalles de un elemento específico
class Detail {
  constructor(id, metaverse) {
    this.init(id); // Llama al método init para inicializar el componente
    // Demo actualizar configurador
    this.metaverse = metaverse;
    this.raycaster = metaverse.raycaster;
    this.shopScreen;
    this.textureLoader = new THREE.TextureLoader();
  }

  init = (id) => {
    // Método para inicializar el componente de detalle de elemento
    new Component(Template, document.body, id); // Crea un nuevo componente con el template proporcionado
    this.component = document.querySelector(`#${id}`); // Encuentra el Detail en el DOM
    //Comprobar si es shop
    if (id === "shop") {
      //Set de icono de Padelcoins
      let icons = this.component.querySelectorAll("[data-icon]");
      SetIcons(icons, Icons.menu, Endpoints.LOCAL_PATHS.UI.ICONS);
      let buyButton = this.component.querySelector("#buy");
      buyButton.addEventListener("click", this.onBuyButtonClick);
      // Prueba de compra
      // buyButton.addEventListener("click", this.shopping);
    } else {
      //Esconder info de compra
      let shopper = this.component.querySelector(".right");
      shopper.classList.add("hidden");
    }
    let cancel = this.component.querySelector("#close");
    cancel.addEventListener("click", this.hide); // Añade un listener para cerrar el componente
    this.initSubscene();
    this.hide; // Inicialmente oculta el componente
    //Detectar cambio de rotación
    window.addEventListener("orientationchange", () => {
      this.updateSubscene();
    });
  };

  scaler = (type) => {
    switch (type) {
      case "floors":
        return 0.7;
        break;
      default:
        return 0.9;
        break;
    }
  };

  updateSubscene = () => {
    setTimeout(() => {
      let element = this.component.querySelector(".canvas"),
        w,
        h = element.clientHeight;
      // Detectar móvil / Orientación y Tablet
      Touch() && Orientation() === "portrait" && !Tablet(this.component)
        ? (w = this.component.clientWidth - 50)
        : (w = element.clientWidth);
      // Detectar si se ha iniciado la sub escena
      if (w > 0 && h > 0) {
        this.renderer && this.renderer.setSize(w, h);
        this.camera.aspect = w / h;
        this.camera.updateProjectionMatrix();
      }
    }, 25);
  };

  set(data, uuid) {
    // Actualiza los elementos del DOM con los datos proporcionados
    this.component.setAttribute("data-id-f", `${data.id_f}`);
    this.component.setAttribute("data-id-m", `${data.id_m}`);
    this.component.setAttribute("data-uuid", `${uuid}`);
    this.component.querySelector(".asset-name").textContent = data.name;
    this.component.querySelector(".asset-description").textContent =
      data.description;
    if (data.price)
      this.component.querySelector(".price-placeholder").textContent =
        data.price;
    // let icons = this.component.querySelectorAll('.icon')
    // icons.forEach(element => {
    //     element.src = `${Endpoints.PATHS.UI.COLLECTION}${Icons.collections[data.type]}.svg`
    // });
    let model = `${data["glb-preview"]}`;
    // Test móvil
    // model = "assets/assets_3D/test/suelosLoungeGym.glb"
    // Cargar modelo 3D
    this.loader.load(model, (gltf) => {
      const model = gltf.scene;
      model.name = data.id;
      //   let scale = this.scaler(data.type);
      //   model.scale.set(scale, scale, scale);
      this.scene.add(model);
      this.show(); // Muestra el componente con los nuevos datos
    });

    Emitter.on('closeDetail',()=>{
      this.hide()
    })
  }

  // Método para mostrar el componente
  show = () => {
    if (document.querySelector(".collections"))
      document.querySelector(".collections").style.pointerEvents = "none"; // Deshabilitar clics para el fondo
    document.querySelector("#close").style.pointerEvents = "auto"; // Habilita el clic para la X del fondo
    this.component.style.display = "block"; // Hace el componente visible
  };

  // Método para ocultar y eliminar el componente
  hide = () => {
    if (this.component) {
      this.component.style.display = "none"; // Hacer el componente invisible
      let panelCollection = document.querySelector(".collections"); // Comprobar si existe collections
      if (panelCollection) {
        panelCollection.style.pointerEvents = "auto"; // Habilitar clics para el fondo
        if (this.controls) this.controls.reset();
        this.scene.traverse((c) => {
          if (c.name === "detailAssetModel") {
            this.scene.remove(c);
          }
        });
      }
    }
  };

  initSubscene = () => {
    const element = this.component.querySelector(".canvas");
    let w,
      h = element.clientHeight;
    Touch() && Orientation() === "portrait" && !Tablet(this.component)
      ? (w = this.component.clientWidth - 70)
      : (w = element.clientWidth);
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      12,
      window.innerWidth / window.innerHeight,
      0.1,
      12
    );
    this.camera.position.z = 5;
    this.camera.position.y = 3;
    this.camera.aspect = w / h;
    this.camera.updateProjectionMatrix();
    this.controls = new OrbitControls(this.camera, element);
    this.controls.minDistance = 0.5;
    this.controls.maxDistance = 10;
    this.controls.enablePan = false;
    // this.controls.enableZoom = false;
    this.controls.minPolarAngle = 1.02;
    this.controls.maxPolarAngle = 1.42;
    this.scene.add(new THREE.HemisphereLight(0xaaaaaa, 0x444444, 3));
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setSize(w, h);
    const style = getComputedStyle(document.body);
    const bgColor = style.getPropertyValue("--color-sec-2");
    this.renderer.setClearColor(new THREE.Color(bgColor));
    element.appendChild(this.renderer.domElement);
    this.renderer.domElement.style.border = "1px solid var(--color-pri-1)";
    this.renderer.domElement.style.borderRadius = "var(--border-radius)";
    let dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("jsm/libs/draco/");
    this.loader = new GLTFLoader();
    this.loader.setDRACOLoader(dracoLoader);
    this.animate();
  };

  animate = () => {
    requestAnimationFrame(this.animate);
    this.renderer.render(this.scene, this.camera);
  };

  /*-- SHOP DETAIL --*/
  onBuyButtonClick = () => {
    this.shopping();
    console.log(
      `Has comprado ${this.component.querySelector(".asset-name").textContent}`
    );
  };

  showSnackbar() {
    const snackbar = document.getElementById("snackbarNOT");
    snackbar.classList.remove("hidden");
    snackbar.classList.add("show");
    setTimeout(() => {
      snackbar.classList.remove("show");
      snackbar.classList.add("hidden");
    }, 3000); // Oculta la alerta después de 3 segundos
  }

  // Prueba de compra
  shopping = () => {
    let id_f = this.component.getAttribute("data-id-f");
    let id_m = this.component.getAttribute("data-id-m");
    let uuid = this.component.getAttribute("data-uuid");
    let price = this.component.querySelector(".price-placeholder").textContent;

    console.log(id_f, id_m);
    // let type = this.component.getAttxribute("data-type");
    // Demo de compra de item
    // let url;
    // Comprobar tipo para definir url
    // this.isConfig(type)
    //   ? (url = Endpoints.API.REST.PURCHASE.AVATAR)
    //   : (url = Endpoints.API.REST.PURCHASE.ROOM);
    // console.log(id, type, url);

    // Agrupar datos para enviar al configurador
    let ids = [];
    ids.push(id_f);
    ids.push(id_m);

    const token = new Cookies().getAuthCookie();
    if (token.length > 0) {
      // Obtenemos valor de su wallet
      Api.metaverse({
        url: Endpoints.API.REST.WALLET,
        method: "Get",
        token: token,
      }).then((r) => {
        if (r && r.status === 200) {
          // Comprobamos que tenga dinero
          if (r.body.wallet >= price) {
            // Si tiene dinero minimo, calculamos su nuevo valor de wallet
            const newValue = r.body.wallet - price;
            // Restamos el precio al valor de su wallet en DB
            Api.metaverse({
              url: Endpoints.API.REST.WALLET,
              method: "PUT",
              token: token,
              body: JSON.stringify({ wallet: -price }),
            }).then((re) => {
              if (re && re.status === 200) {
                // Actualizamos el valor del wallet a nivel de front
                this.metaverse.menu.setCoins(newValue);
                // Realizamos la compra de ambos objetos
                Api.metaverse({
                  url: Endpoints.API.REST.PURCHASE.AVATAR,
                  method: "POST",
                  token: token,
                  body: JSON.stringify({
                    id: id_f,
                  }),
                  simple: true,
                }).then((res) => {
                  this.hide();
                  // Quitar funcionalidad boton compra
                  this.raycaster.removeEventFromModel(uuid);
                  // Actualizar configurador
                  this.metaverse.updateConfigurator(ids);
                });
                Api.metaverse({
                  url: Endpoints.API.REST.PURCHASE.AVATAR,
                  method: "POST",
                  token: token,
                  body: JSON.stringify({
                    id: id_m,
                  }),
                  simple: true,
                }).then((res) => {
                  this.hide();
                  // Cambiar mat
                  this.shopScreen.traverse((c) => {
                    c.isMesh &&
                      this.textureLoader.load(
                        "assets/media/store/compraRealizada.png",
                        (texture) => {
                          c.material.map = texture;
                          c.material.map.flipY = false;
                        }
                      );
                  });
                });
              }
            });
          } else {
            this.showSnackbar();
            console.error("You don't have enough money");
          }
        }
      });
    }
  };

  delete() {
    this.scene.traverse((c) => {
      c.isMesh && c.material.map.dispose() && c.geometry.dispose();
    });
    this.scene.clear();
    this.scene.remove();
    this.component.remove();
  }
}

export default Detail;
