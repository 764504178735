import * as THREE from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import Template from "./Configurator.html?raw";
import Component from "../../Utils/Component";
import Endpoints from "../../Services/Endpoints";
import API from "../../Services/Api";
import { Touch, Orientation } from "../../Functions/Device";
import { SetIcons } from "../../Functions/Interface";
import { SetColor } from "../../Functions/Scene";
import Icons from "../../Data/Icons.json";
import Cookies from "../../Services/Cookies";
import AvaConfigLocalData from "../../Data/Player.json";
import DefaultPlayer from "../../../metaverse.json"; // !!! REVISAR JSON
import Storage from "../../Services/Storage";
import { Scene, Emitter, Menu } from "../../SceneManager";

class Configurator {
  metascene = Scene
  emitter = Emitter
  reset = Menu.reset
  constructor() {
    this.get();
    this.ready = false;
  }

  get = () => {
    const token = new Cookies().getAuthCookie();
    if (token.length > 0) {
      //GET CONFIGURATOR DATA
      API.metaverse({
        url: Endpoints.API.REST.CONFIG,
        method: "GET",
        token: token,
      }).then((response) => {
        if (response.status === 200) {
          let data = response.body.live;
          this.female = data.female;
          this.male = data.male;

          this.skins = data.skins;

          this.female.skins = data.skins;
          this.male.skins = data.skins;
          this.female.hairs = data.hairs;
          this.male.hairs = data.hairs;

          API.metaverse({
            url: Endpoints.API.REST.PURCHASE.AVATAR,
            method: "GET",
            token: token,
          }).then((r) => {
            if (r && r.status == 200) {
              const outF = [];
              const outM = [];
              r.body.forEach((e) => {
                e.id.includes("f") ? outF.push(e) : outM.push(e);
              });

              // Método pata evitar ForEach y Push
              let outfit_f = AvaConfigLocalData.shop.female.filter((item) =>
                outF.map((item) => item.id).includes(item.id)
              );
              this.female.clothes = [...this.female.clothes, ...outfit_f];
              // Método pata evitar ForEach y Push
              let outfit_m = AvaConfigLocalData.shop.male.filter((item) =>
                outM.map((item) => item.id).includes(item.id)
              );
              this.male.clothes = [...this.male.clothes, ...outfit_m];
            }
            // GET USER DATA + AVATAR
            API.metaverse({
              url: Endpoints.API.REST.USER,
              method: "GET",
              token: token,
            }).then((res) => {
              if (res.status === 200) {
                this.userId = res.body._id;
                this.usernameIni = res.body.userName;
                this.username = res.body.userName;
                this.character = res.body.avatar;
                this.tempCharacter = JSON.parse(
                  JSON.stringify(res.body.avatar)
                );
                this.gender = res.body.avatar.gender;
                this.skin = res.body.avatar.skin;
                this.hair = res.body.avatar.hair;
                if (!this.hair) this.character.hair = Data.player.hair;

                this.set();
                this.initSubscene();
                this.initPlayer(
                  `${Endpoints.PATHS.AVATAR.MODEL}${this.character.male.head}.glb`,
                  `${Endpoints.PATHS.AVATAR.MODEL}${this.character.male.outfit}.glb`,
                  `${Endpoints.PATHS.AVATAR.MODEL}${this.character.male.accessory}.glb`,
                  "male",
                  this.gender === "male" ? true : false
                );
                this.initPlayer(
                  `${Endpoints.PATHS.AVATAR.MODEL}${this.character.female.head}.glb`,
                  `${Endpoints.PATHS.AVATAR.MODEL}${this.character.female.outfit}.glb`,
                  `${Endpoints.PATHS.AVATAR.MODEL}${this.character.female.accessory}.glb`,
                  "female",
                  this.gender === "female" ? true : false
                );
                this.setActive(this.character[this.gender]);
                this.render();
                this.ready = true;
              } else {
                console.log("ERROR");
              }
            });
          });
        } else {
          // Eliminar la cookie con el token
          new Cookies().deleteAuthCookie();
          location.reload();
        }
      });
    } else {
      console.log("ERROR");
    }
  };
  
  // Set UI
  set = () => {
    new Component(Template, document.body);
    this.component = document.querySelector(".configurator");
    window.addEventListener("orientationchange", () => {
      this.setRotation();
    });
    window.addEventListener("resize", () => {
      let height = this.component.querySelector(
        `.${Orientation()}`
      ).clientHeight;
      this.renderer && this.renderer.setSize(height / 2, height);
    });
    Touch() && Orientation() === "landscape"
      ? this.component.classList.add("configurator-landscape")
      : this.component.classList.remove("configurator-landscape");

    this.hide();

    let submit = this.component.querySelector("#submit");
    let cancel = this.component.querySelector("#cancel");

    this.tabs = this.component.querySelectorAll(".meta-tab");
    this.selectGender = this.component.querySelectorAll(
      "button[type='gender']"
    );
    this.setTabs(this[this.gender]);

    let icons = this.component.querySelectorAll("[data-icon]");
    SetIcons(icons, Icons.configurator, Endpoints.LOCAL_PATHS.UI.CONFIG);

    this.selectGender.forEach((selector) => {
      let target = selector.getAttribute("data-target");
      if (target === this.gender) {
        selector.classList.add("active");
        selector.classList.remove("up");
        selector.classList.add("down");
      }
      selector.addEventListener("click", this.filter);
    });

    let userInput = this.component.querySelector("input#name");
    userInput.value = this.username;
    userInput.addEventListener("input", this.changeName);
    submit.addEventListener("click", this.send);
    cancel.addEventListener("click", this.hide);
  };

  //TABS
  setTabs = (data) => {
    let color = false;
    this.tabs.forEach((element) => {
      let target = element.querySelector("div.tab-content");
      let id = target.getAttribute("id");
      let item = target.getAttribute("items");
      item === "skin" || item === "hair" ? (color = true) : (color = false);
      this[id] = this.component.querySelector(`#${id}`);
      this.setList(this[id], data[id], item, color);
    });
  };

  // List elements in tab
  setList = (element, items, type, color) => {
    items.forEach((item) => {
      !color
        ? this.setButtonImg(item, element, type)
        : this.setButtonColor(item, element, type);
    });
  };

  setRotation = () => {
    if (Touch()) {
      this.scene.remove(this[`group_${this.gender}`]);
      this.scene.clear();
      let canvas = this.component.querySelectorAll("canvas");
      canvas.forEach((element, index) => {
        element.remove();
      });
      setTimeout(() => {
        this.initSubscene();
        this.scene.add(this[`group_${this.gender}`]);
      }, 25);
    }
  };

  setButtonImg = (item, element, type) => {
    let button = document.createElement("button");
    let img = new Image();
    img.src = item.preview;
    img.setAttribute("data", item.id);
    button.setAttribute("data", item.id);
    button.setAttribute("model", item.file);
    button.classList.add(type);
    button.classList.add("button-selecter");
    button.classList.add("button-x");
    button.classList.add("up");
    button.appendChild(img);
    element.appendChild(button);
    button.addEventListener("click", (e) => {
      this.selecter(e, type);
    });
  };

  setButtonColor = (item, element, type) => {
    let button = document.createElement("button");
    let span = document.createElement("span");
    button.classList.add(`button-color-${type}`);
    button.classList.add("button-m");
    button.classList.add("up");
    if (type === "skin" && item.rgbn === this.skin) {
      button.classList.add("active");
    }
    if (type === "hair" && item.rgbn === this.hair) {
      button.classList.add("active");
    }
    span.style.background = `#${item.hex}`;
    button.setAttribute("data", item.rgbn);
    span.setAttribute("data", item.rgbn);
    button.appendChild(span);
    element.appendChild(button);
    button.addEventListener("click", (e) => {
      this.selectColor(e, type);
    });
  };

  setActive = (data) => {
    let head = this.component.querySelector(`img[data=${data.head}]`);
    let outfit = this.component.querySelector(`img[data=${data.outfit}]`);
    // let accessory = this.component.querySelector(`img[data=${data.accessory}]`)
    if (head) head.classList.add("active");
    if (outfit) outfit.classList.add("active");
    // accessory.classList.add('active')
  };

  selecter = (e, element) => {
    let data = e.target.getAttribute("data");
    let target = this.component.querySelectorAll(`button.${element} img`);
    let button = this.component.querySelector(`button[data=${data}]`)
    let model = button.getAttribute("model");
    this.setCharacter(element, model, button);
    target.forEach((element) => {
      element.classList.remove("active");
    });
    let buttons = this.component.querySelectorAll(`button.${element}`);
    buttons.forEach(bt => {
      bt.removeAttribute('disabled')
    });
    button.setAttribute('disabled',true)
    e.target.matches("button")
      ? e.target.firstChild.classList.add("active")
      : e.target.classList.add("active");
    this.tempCharacter[this.gender][element] = data;
  };

  filter = (e) => {
    this.selectGender.forEach((element) => {
      element.classList.remove("active");
      element.classList.remove("down");
      element.classList.add("up");
    });
    let gender;
    if (e.target.matches("button")) {
      gender = e.target.getAttribute("data-target");
      e.target.classList.add("active");
      e.target.classList.add("down");
    } else {
      let parent = e.target.parentNode;
      parent.classList.add("active");
      parent.classList.add("down");
      gender = parent.getAttribute("data-target");
    }
    this.head.innerHTML =
      this.clothes.innerHTML =
      this.skins.innerHTML =
      this.hairs.innerHTML = // !!??
        "";
    this.setTabs(this[gender]);
    this.character.gender = gender;
    this.gender = gender;
    gender === "male"
      ? this.scene.remove(this.group_female)
      : this.scene.remove(this.group_male);
    this.scene.add(this[`group_${gender}`]);
    this.setActive(this.character[gender]);
  };
  //END TABS

  selectColor = (e, type) => {
    let target = this.component.querySelectorAll(`.button-color-${type}`);
    target.forEach((element) => {
      element.classList.remove("active");
    });
    e.target.matches("button")
      ? e.target.classList.add("active")
      : e.target.parentElement.classList.add("active");
    let data = e.target.getAttribute("data");
    this.tempCharacter[type] = this[type] = data;

    SetColor(this.group_male, type, data);
    SetColor(this.group_female, type, data);
  };

  hide = () => {
    this.component.style.visibility = "hidden";
    this.reset("configurator");
    if (this.controls) this.controls.reset();
  };
  show = () => {
    this.component.style.visibility = "visible";
  };

  changeName = (e) => {
    this.username = e.target.value;
    this.character.username = e.target.value;
  };
  getUsername() {
    return this.usernameIni;
  }
  getUserId() {
    return this.userId;
   
  }

  //END SKINS

  hex = (rgb) =>
    `#${rgb
      .match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
      .slice(1)
      .map((n) => parseInt(n, 10).toString(16).padStart(2, "0"))
      .join("")}`;

  //SUBSCENE
  initSubscene = () => {
    const element = this.component.querySelector(`.${Orientation()} .model`);
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      25,
      window.innerWidth / window.innerHeight,
      0.1,
      100
    );
    this.camera.position.z = 15;
    this.camera.position.y = 3;
    this.camera.aspect = 496 / 1000;
    this.camera.updateProjectionMatrix();
    this.controls = new OrbitControls(this.camera, element);
    this.controls.minDistance = 2;
    this.controls.maxDistance = 16;
    this.controls.enablePan = false;
    this.controls.enableZoom = false;
    this.controls.minPolarAngle = 1.02;
    this.controls.maxPolarAngle = 1.42;
    this.renderer = new THREE.WebGLRenderer({ antialias: true });

    let height = this.component.querySelector(`.${Orientation()}`).clientHeight;
    this.renderer.setSize(height / 2, height);

    let container = document.querySelector(".configurator .container");
    let bgcolor = window
      .getComputedStyle(container, null)
      .getPropertyValue("background-color");
    this.renderer.setClearColor(this.hex(bgcolor));
    element.appendChild(this.renderer.domElement);

    let dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("jsm/libs/draco/");
    this.loader = new GLTFLoader();
    this.loader.setDRACOLoader(dracoLoader);
    this.animation = new GLTFLoader();
    this.animation.setDRACOLoader(dracoLoader);
    this.scene.add(new THREE.HemisphereLight(0xaaaaaa, 0x444444, 3));
  };

  initPlayer = (head, outfit, accessory, type, selected) => {
    this[`group_${type}`] = new THREE.Group();
    this.loader.load(head, (data_head) => {
      this.loader.load(outfit, (data_outfit) => {
        this.loader.load(accessory, (data_accessory) => {
          this[`model_head_${type}`] = data_head.scene;
          this[`model_outfit_${type}`] = data_outfit.scene;
          this[`model_accessory_${type}`] = data_accessory.scene;
          this[`group_${type}`].scale.set(3.35, 3.35, 3.35);
          this[`group_${type}`].position.set(0, -3.1, 0);
          this[`group_${type}`].add(this[`model_head_${type}`]);
          this[`group_${type}`].add(this[`model_outfit_${type}`]);
          this[`group_${type}`].add(this[`model_accessory_${type}`]);
          // Añadir el traverse para cambiar color de piel + pelo
          SetColor(this[`group_${type}`], "skin", this.skin);
          SetColor(this[`group_${type}`], "hair", this.hair);
          selected && this.scene.add(this[`group_${type}`]);
          this.animator(type, null);
        });
      });
    });
  };

  animator = (type, piece) => {
    this[`mixer_head_${type}`] = new THREE.AnimationMixer(
      this[`model_head_${type}`]
    );
    this[`mixer_outfit_${type}`] = new THREE.AnimationMixer(
      this[`model_outfit_${type}`]
    );
    this[`mixer_accessory_${type}`] = new THREE.AnimationMixer(
      this[`model_accessory_${type}`]
    );
    this.animation.setPath(`./assets/assets_3D/character/animations_${type}/`);
    this.animation.load(`idle.glb`, (anim) => {
      piece !== null && this[`group_${type}`].add(piece);
      this.clip = anim.animations[0];
      this[`action_head_${type}`] = this[`mixer_head_${type}`].clipAction(
        this.clip
      );
      this[`action_outfit_${type}`] = this[`mixer_outfit_${type}`].clipAction(
        this.clip
      );
      this[`action_accessory_${type}`] = this[
        `mixer_accessory_${type}`
      ].clipAction(this.clip);
      this[`action_head_${type}`].play();
      this[`action_outfit_${type}`].play();
      this[`action_accessory_${type}`].play();
    });
  };

  setCharacter = (piece, model) => {
    this.loader.load(model, (data) => {
      this[`model_${piece}_${this.gender}`].traverse((c) => {
        c.isMesh && c.geometry.dispose();
        c.isMesh && c.material.map && c.material.map.dispose();
      });
      this[`group_${this.gender}`].remove(
        this[`model_${piece}_${this.gender}`]
      );
      this[`model_${piece}_${this.gender}`] = data.scene;
      SetColor(data.scene, "skin", this.skin);
      SetColor(data.scene, "hair", this.hair);
      this.animator(this.gender, data.scene);
    });
  };

  render() {
    this.animate = (timestamp) => {
      this.elapsedTime = (timestamp - this.previousTimeStamp) / 1000;
      this.previousTimeStamp = timestamp;
      if (this[`mixer_head_${this.gender}`])
        this[`mixer_head_${this.gender}`].update(this.elapsedTime);
      if (this[`mixer_outfit_${this.gender}`])
        this[`mixer_outfit_${this.gender}`].update(this.elapsedTime);
      if (this[`mixer_accessory_${this.gender}`])
        this[`mixer_accessory_${this.gender}`].update(this.elapsedTime);
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.animate);
    };
    this.previousTimeStamp = performance.now();
    requestAnimationFrame(this.animate);
    this.renderer.render(this.scene, this.camera);
  }

  send = () => {
    // Guardar los datos (local storage en este caso)
    // Storage.set(
    //   `${import.meta.env.VITE_ENVIRONMENT}-avatarData-${
    //     import.meta.env.VITE_METAVERSO
    //   }`,
    //   JSON.stringify(this.character)
    // );
    // Guardar el cambio de nombre de usuario
    // if (this.usernameIni !== this.username) {
    //   this.usernameIni = this.username;
    //   this.exit();
    // } else {
    //   this.exit();
    // }
    this.character = JSON.parse(JSON.stringify(this.tempCharacter));
    const token = new Cookies().getAuthCookie();
    API.metaverse({
      url: Endpoints.API.REST.AVATAR,
      method: "PUT",
      token: token,
      body: JSON.stringify(this.character),
    }).then(() => {
      if (this.usernameIni !== this.username) {
        API.metaverse({
          url: Endpoints.API.REST.USER,
          method: "PUT",
          token: token,
          body: JSON.stringify({ userName: this.username }),
        }).then(() => {
          this.usernameIni = this.username;
          this.exit();
        });
      } else {
        this.exit();
      }
    });
  };

  exit = () => {
    let preview = this.metascene.getObjectByName("meta-avatar");
    this.metascene.remove(preview);
    this.setAvatar(preview.position, preview.group.rotation, true);
    this.hide();
  };

  update = (waypointRot) => {
    // Storage.get(
    //   `${import.meta.env.VITE_ENVIRONMENT}-avatarData-${
    //     import.meta.env.VITE_METAVERSO
    //   }`
    // ).then((data) => {
    //   this.avatarData = JSON.parse(data);
    // });
    this.setAvatar(new THREE.Vector3(0, 0, 0), waypointRot, false);
  };

  setAvatar = (position, rotation, status) => {
    let player = {
      head: `${Endpoints.PATHS.AVATAR.MODEL}${
        this.character[this.gender].head
      }.glb`,
      outfit: `${Endpoints.PATHS.AVATAR.MODEL}${
        this.character[this.gender].outfit
      }.glb`,
      accessory: `${Endpoints.PATHS.AVATAR.MODEL}${
        this.character[this.gender].accessory
      }.glb`,
      skin: this.character.skin,
      hair: this.character.hair,
      gender: this.gender,
    };
    /* this.metaverse.updatePlayer(
      {
        player: player,
        position: position,
        rotation: rotation,
      },
      status
    ); */
    this.emitter.trigger('updatePlayer',[{
      player: player,
      position: position,
      rotation: rotation,
      status
    }])
  };

  getLocalDataConfigurator() {
    this.userId = null;
    this.female = AvaConfigLocalData.female;
    this.male = AvaConfigLocalData.male;

    this.skins = AvaConfigLocalData.skins;

    this.female.skins = AvaConfigLocalData.skins;
    this.male.skins = AvaConfigLocalData.skins;
    this.female.hairs = AvaConfigLocalData.hairs;
    this.male.hairs = AvaConfigLocalData.hairs;

    Storage.get(
      `${import.meta.env.VITE_ENVIRONMENT}-avatarData-${
        import.meta.env.VITE_METAVERSO
      }`
    ).then((avatarData) => {
      avatarData = JSON.parse(avatarData);
      this.character = avatarData;
      this.gender = avatarData.gender;
      this.skin = avatarData.skin;
      this.hair = avatarData.hair;
      this.usernameIni = avatarData.username;
      this.username = avatarData.username;
      if (!this.usernameIni || !this.username)
        this.usernameIni = this.username = "Player";

      this.set();
      this.initSubscene();
      this.initPlayer(
        `${Endpoints.LOCAL_PATHS.AVATAR.MODEL}${this.character.male.head}.glb`,
        `${Endpoints.LOCAL_PATHS.AVATAR.MODEL}${this.character.male.outfit}.glb`,
        `${Endpoints.LOCAL_PATHS.AVATAR.MODEL}${this.character.male.accessory}.glb`,
        "male",
        this.gender === "male" ? true : false
      );
      this.initPlayer(
        `${Endpoints.LOCAL_PATHS.AVATAR.MODEL}${this.character.female.head}.glb`,
        `${Endpoints.LOCAL_PATHS.AVATAR.MODEL}${this.character.female.outfit}.glb`,
        `${Endpoints.LOCAL_PATHS.AVATAR.MODEL}${this.character.female.accessory}.glb`,
        "female",
        this.gender === "female" ? true : false
      );
    });
  }

  addItems = (items) => {
    // Encontrar item en array por default
    let item_f = AvaConfigLocalData.shop.female.find((item) =>
      items.includes(item.id)
    );
    let item_m = AvaConfigLocalData.shop.male.find((item) =>
      items.includes(item.id)
    );

    // Agregar item al array
    item_f && this.female.clothes.push(item_f);
    item_m && this.male.clothes.push(item_m);
    // Regenerar tabs
    this.head.innerHTML =
      this.clothes.innerHTML =
      this.skins.innerHTML =
      this.hairs.innerHTML =
        "";
    this.setTabs(this[this.gender]);
    this.setActive(this.character[this.gender]);
  };
}

export default Configurator;
