import Template from "./Menu.html?raw";
import Component from "../../Utils/Component";
import Endpoints from "../../Services/Endpoints";
import Cookies from "../../Services/Cookies";
import API from "../../Services/Api";
import { SetIcons } from "../../Functions/Interface";
import Icons from "../../Data/Icons.json";
import { Chat, Configurator, Settings, Emitter } from "../../SceneManager";

class MenuComponent {
  constructor() {
    this.on = false;
    this.set();
  }

  get = () => {
    const token = new Cookies().getAuthCookie();
    API.metaverse({
      url: Endpoints.API.REST.ROOM,
      method: "GET",
      token: token,
    }).then((res) => {
      if (res.status === 200) {
        this.set(res.body.live);
      } else {
        console.log("ERROR");
      }
    });
  };

  set = () => {
    new Component(Template, document.body, "menu");
    this.component = document.querySelector("#menu");
    this.targets = this.component.querySelectorAll("button");
    let icons = this.component.querySelectorAll("[data-icon]");
    this.coins = this.component.querySelector("[data-coins]");
    SetIcons(icons, Icons.menu, Endpoints.LOCAL_PATHS.UI.ICONS);
    
    // this.virtuaAssistant = new VirtualAssistant();
    // this.collections = new Collections('collections',this.reset, false)
    // this.collections.set(collecction)
    
    this.targets.forEach((element) => {
      element.addEventListener("click", this.show);
      element.addEventListener("keydown", function (event) {
        // Verifica si la tecla presionada es "Enter"
        if (event.code === "Enter") {
          // Evita la propagación del evento si es "Enter"
          event.preventDefault();
        }
      });
    });
    //Comprobar monedas recolectadas por el usuario
    const token = new Cookies().getAuthCookie();
    if (token.length > 0) {
      API.metaverse({
        url: Endpoints.API.REST.WALLET,
        method: "GET",
        token: token,
      }).then((res) => {
        if (res&&res.status === 200) {
          this.setCoins(res.body.wallet);
        }
        else{
          console.log(res);
        }
        
      })
    }
  };

  reset = (element) => {
    let target = document.querySelector(`button[data=${element}]`);
    target.classList.remove("active");
    this.on = false;
  };

  show = (e) => {
    Emitter.trigger('closeDetail')
    let data;
    e.target.matches("button")
      ? (data = e.target.getAttribute("data"))
      : (data = e.target.parentElement.getAttribute("data"));
    const activer = () => {
      this.on = true;
      this.targets.forEach((element) => {
        element.classList.remove("active");
      });
      
      switch (data) {
        case 'chat': Chat.show(); break;
        case 'configurator': Configurator.show(); break;
        case 'settings': Settings.show(); break;
        default: break;
      }
      e.target.matches("button")
        ? e.target.classList.add("active")
        : e.target.parentElement.classList.add("active");
    };
    let target = this.component.querySelector(`button[data=${data}]`);
    let active = target.classList.contains("active");
    try {
      Chat.hide();
      Configurator.hide();
      Settings.hide();
    } catch {}

    if(active){
      target.classList.remove("active")
      switch (data) {
        case 'chat': Chat.hide(); break;
        case 'configurator': Configurator.hide(); break;
        case 'settings': Settings.hide(); break;
        default: break;
      }
    }
    else{
      activer()
    }
  };

  hide(){
    Chat.hide();
    Configurator.hide();
    Settings.hide();
  }

  setCoins = (n) => {
    this.coins.innerText = n;
  };
  updateCoins = (n) => {
    const newValue = parseInt(this.coins.innerText) + n;
    this.setCoins(newValue);
  };
}

export default MenuComponent;
