import * as THREE from 'three'

class Machine {
  constructor(animations) {
    this.animations = animations
  }
}

class StateActions {
  constructor() {
    this.states = {}
    this.currentState = null
  }

  AddState(name, type) {
    this.states[name] = type
  }

  SetState(name) {
    const prevState = this.currentState
    if (prevState) {
      if (prevState.Name == name) {
        return
      }
      prevState.Exit()
    }
    const state = new this.states[name](this)
    this.currentState = state
    state.Enter(prevState)
  }

  Update(timeElapsed, input, joystick) {
    if (this.currentState) {
      this.currentState.Update(timeElapsed, input, joystick)
    }
  }
}

class StateController extends StateActions {
  constructor(proxy) {
    super()
    this.proxy = proxy
    this.Init()
  }

  Init() { 
    this.AddState('idle', IdleState)
    this.AddState('walk', WalkState)
    this.AddState('run', RunState)
    this.AddState('dance', DanceState)
  }
}

class State {
  constructor(parent) {
    this.parent = parent
  }
  Enter() {}
  Exit() {}
  Update() {}
}

class IdleState extends State {
  constructor(parent) {
    super(parent)
  }

  get Name() {
    return 'idle'
  }

  Enter(prevState) {
    const idleAction = this.parent.proxy.animations['idle'].action
    if (prevState) {
      const prevAction = this.parent.proxy.animations[prevState.Name].action
      
      idleAction.time = 0.0
      idleAction.enabled = true
      idleAction.setEffectiveTimeScale(1.0)
      idleAction.setEffectiveWeight(1.0)
      idleAction.crossFadeFrom(prevAction, 0.25, true)
      idleAction.play()
    } else {
      idleAction.play()
    }
  }

  Exit() {
  }
  Update(_, input, joystick) {
    if (input.keys.forward || input.keys.backward || input.keys.left || input.keys.right || input.keys.u || input.keys.b || input.keys.l || input.keys.r || joystick) {
      this.parent.SetState('walk')
    } 
    else if (input.keys.space) {
      this.parent.SetState('dance')
    }
  }
}

class WalkState extends State {
  constructor(parent) {
    super(parent)
  }
  get Name() {
    return 'walk'
  }

  Enter(prevState) {
    const curAction = this.parent.proxy.animations['walk'].action
    if (prevState) {
      const prevAction = this.parent.proxy.animations[prevState.Name].action
      curAction.enabled = true

      curAction.crossFadeFrom(prevAction, 0.15, true)
      curAction.play()
    } else {
      curAction.play()
    }
  }

  Exit() {
  }
  
  Update(timeElapsed, input, joystick) {
    if (input.keys.forward || input.keys.backward || input.keys.left || input.keys.right || input.keys.u || input.keys.b || input.keys.l || input.keys.r || joystick) {
      if (input.keys.shift) {
        this.parent.SetState('run')
      }
      return
    }

    this.parent.SetState('idle')
  }
}

class RunState extends State {
    constructor(parent) {
      super(parent)
    }
  
    get Name() {
      return 'run'
    }
  
    Enter(prevState) {
      const curAction = this.parent.proxy.animations['run'].action
      if (prevState) {
        const prevAction = this.parent.proxy.animations[prevState.Name].action
  
        curAction.enabled = true
  
        if (prevState.Name == 'walk') {
          const ratio = curAction.getClip().duration / prevAction.getClip().duration
          curAction.time = prevAction.time * ratio
        } else {
          curAction.time = 0.0
          curAction.setEffectiveTimeScale(1.0)
          curAction.setEffectiveWeight(1.0)
        }

        curAction.crossFadeFrom(prevAction, 0.1, true)
        curAction.play()
      } else {
        curAction.play()
      }
    }
  
    Exit() {
    }
  
    Update(_, input, joystick) {
      if (input.keys.forward || input.keys.backward || input.keys.left || input.keys.right || input.keys.u || input.keys.b || input.keys.l || input.keys.r || joystick) {
        if (!input.keys.shift) {
          this.parent.SetState('walk')
        }
        return
      }
      this.parent.SetState('idle')
    }
}

class DanceState extends State {
  constructor(parent) {
    super(parent)

    this.FinishedCallback = () => {
      this.Finished()
    }
  }
  get Name() {
    return 'dance'
  }
  Enter(prevState) {
    const curAction = this.parent.proxy.animations['dance'].action
    const mixer = curAction.getMixer()
    mixer.addEventListener('finished', this.FinishedCallback)
    if (prevState) {
      const prevAction = this.parent.proxy.animations[prevState.Name].action
      curAction.reset()
      curAction.setLoop(THREE.LoopOnce, 1)
      curAction.clampWhenFinished = true
      curAction.crossFadeFrom(prevAction, 0.2, true)
      curAction.play()
    } else {
      curAction.play()
    }
  }

  Finished() {
    this.Cleanup()
    this.parent.SetState('idle')
  }

  Cleanup() {
    const action = this.parent.proxy.animations['dance'].action
    
    action.getMixer().removeEventListener('finished', this.CleanupCallback)
  }

  Exit() {
    this.Cleanup()
  }

  Update(_,input,joystick) {
    if (input.keys.forward || input.keys.backward || input.keys.left || input.keys.right || input.keys.u || input.keys.b || input.keys.l || input.keys.r || joystick){
      this.Finished()
    }
  }
}

export{
    Machine,
    StateController
}