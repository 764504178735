import Template from "./Chat.html?raw";
import Component from "../../Utils/Component";
import API from "../../Services/Api";
import Endpoints from "../../Services/Endpoints";
import Cookies from "../../Services/Cookies";
import { User, Socket, Menu} from "../../SceneManager";

class ChatComponent {
  user = User
  socket = Socket
  reset = Menu.reset
  constructor(selector) {
    this.set(selector);
    this.reportBoxCreated = false;
  }

  set = target => {
    let selector = document.querySelector(target);
    new Component(Template, selector);
    this.component = document.querySelector("#chat");
    this.hide();

    const sender = this.component.querySelector("#send-message");
    this.message = this.component.querySelector("#message");
    this.messages = this.component.querySelector(".chat-messages");

    sender.addEventListener("click", () => this.send());
    this.message.addEventListener("keypress", (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        sender.click();
      }
    });
  };

  hide = () => {
    this.component.style.visibility = "hidden";
    this.reset("chat");
  };

  show = () => {
    this.component.style.visibility = "visible";
    setTimeout(() => {
      this.message.focus();
    }, 100);
  };

  send = () => {
    let message = this.message.value;
    let payload = {
      userId: this.user._id,
      who: this.user.userName,
      msn: message,
      timestamp: Date.now(),
    };
    if (message !== "") {
      this.socket.message(payload);
      this.msnOut(payload.who, payload.msn);
    }
  };

  msnOut(who, msn) {
    const data = {};
    const element = document.createElement("div");
    const messager = document.createElement("div");
    const user = document.createElement("div");
    element.classList.add("msn-in");
    user.classList.add("who-div");
    messager.classList.add("who-msn");

    if (this.user.userName !== who) {
      element.addEventListener("click", () => {
        this.reportarMensaje(element, data);
      });
      element.addEventListener("mouseleave", () => {
        this.ocultarReport();
      });
    }
    //Replace with username
    user.textContent = who + ":";
    messager.textContent = msn;

    element.appendChild(user);
    element.appendChild(messager);

    this.messages.appendChild(element);
    this.messages.scrollTop = this.messages.scrollHeight;
    if (this.user.userName === who) {
      user.classList.add("who-me");
      element.classList.add("msn-me");
      this.message.value = "";
    }
  }

  reportarMensaje(element, data) {
    if (!this.reportBoxCreated) {
      const mensaje = element;
      const reportBox = document.createElement("button");
      reportBox.addEventListener("mouseleave", () => {
        this.ocultarReport();
      });
      reportBox.id = "report-box";
      reportBox.classList.add("report-box");
      reportBox.innerText = "Report message";
      reportBox.onclick = () => {
        this.mostrarCuadro(data, element);
      };

      mensaje.appendChild(reportBox);

      // Muestra el cuadro chiquito
      reportBox.style.display = "flex";

      this.reportBoxCreated = true;
    }
  }

  ocultarReport() {
    const reportBox = this.component.querySelector(".report-box");
    if (reportBox) {
      // Oculta el cuadro chiquito
      if (reportBox.parentNode) {
        reportBox.parentNode.removeChild(reportBox);
        this.reportBoxCreated = false;
        reportBox.style.display = "none";
      }
    }
  }

  mostrarCuadro(data, element) {
    const reportBox = document.querySelector(".report-container");
    const acceptReport = reportBox.querySelector(".aceptar");
    const cancelReport = reportBox.querySelector(".cancelar");

    reportBox.style.display = "flex";

    acceptReport.addEventListener("click", () => {
      const body = {
        userId: this.user._id,
        userName: this.user.userName,
        message: data,
      };

      reportBox.style.display = "none";
      API.metaverse({
        url: Endpoints.API.REST.BANREQUEST,
        method: "POST",
        token: new Cookies().getAuthCookie(),
        body: JSON.stringify(body),
      }).then((res) => {
        if (res.status === 200) {
          reportBox.style.display = "none";
          this.reportSuccessfully(element);
        } else {
          reportBox.style.display = "none";
          this.reportUnsuccessfully();
        }
      });
    });

    cancelReport.addEventListener("click", () => {
      reportBox.style.display = "none";
    });
  }
  update = (data) => {
    console.log("CHAT");
    console.log(data);
  };

  reportSuccessfully(element) {
    var snackbar = document.getElementById("snackbarOK");
    snackbar.className = "show";

    setTimeout(function () {
      snackbar.className = snackbar.className.replace("show", "");
    }, 2500);
    element.style.visibility = "hidden";
  }

  reportUnsuccessfully() {
    var snackbar = document.getElementById("snackbarNOT");
    snackbar.className = "show";
    setTimeout(function () {
      snackbar.className = snackbar.className.replace("show", "");
    }, 2500);
  }
}

export default ChatComponent;
