import Metaverse from "../Metaverse";
import * as THREE from "three";
import { Scene, Cam, Player } from "../SceneManager";

export default class Raycaster {
  constructor() {
    // Creamos una instancia de la clase Metaverse para acceder a la escena y la cámara.
    
    
    this.raycastDistance = 6;

    // Capturamos el elemento del lienzo (canvas) para agregar los eventos 'pointermove' y 'click'.
    this.canvas = document.querySelector("canvas.webgl");

    // Creamos un Raycaster de Three.js para realizar intersecciones con objetos en la escena.
    this.raycaster = new THREE.Raycaster();
    this.pointer = new THREE.Vector2(); // Vector2 para almacenar las coordenadas normalizadas del puntero.
    this.modelEvents = {}; // Objeto para almacenar las funciones de eventos asociadas a los modelos.
    this.objectPos = new THREE.Vector3(); // Vector3 vacio donde se almacena la posicion global de los elementos seleccionados.

    // Agregamos un 'eventListener' para el evento 'pointermove' en el lienzo, llamando a la función onPointerMove.
    this.canvas.addEventListener("pointermove", this.mousePosition.bind(this));

    // En vez de lanzar el raycast cada movimiento de raton
    // Se lanza de forma perdiodica a forma de "sonar" cada 0.25s
    setInterval(() => {
      this.onPointerMove();
    }, 250);

    // Agregamos un 'eventListener' para el evento 'click' en el lienzo, llamando a la función handleDynamicClick.
    this.canvas.addEventListener("mousedown", this.handleMouseDown.bind(this));
    this.canvas.addEventListener("click", this.handleMouseClick.bind(this));
  }

  // Función para actualizar el raycaster y detectar intersecciones con objetos en la escena.
  update() {
    this.raycaster.setFromCamera(this.pointer, Cam.camera);
    this.intersects = this.raycaster.intersectObjects(Scene.children);
  }

  // Funcion conocer posicion raton
  mousePosition(event) {
    this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
  }

  // Función para manejar el evento 'pointermove' y actualizar las coordenadas normalizadas del puntero.
  onPointerMove() {
    if (!this.pointer) return;
    this.raycaster.setFromCamera(this.pointer, Cam.camera);
    this.intersects = this.raycaster.intersectObjects(Scene.children);
    let selector = document.querySelector(".webgl");
    // Si el puntero está sobre un objeto interactivo, lanzar 'mouseenter'.
    if (this.intersects.length > 0) {
      this.currentHoveredObject = this.intersects[0].object;
      // Calculamos la distancia del player al objeto interactuable
      this.distance = Player.position.distanceTo(
        this.currentHoveredObject.position
      );
      // Distancia entre avatar y objeto fijado
      if (this.distance < this.raycastDistance) {
        // El puntero acaba de "entrar" al objeto por primera vez
        if (
          this.currentHoveredObject.raycaster === true &&
          this.currentHoveredObject !== this.hoveredObject
        ) {
          this.hoveredObject = this.currentHoveredObject;
          this.handleMouseEvent("mouseenter");
        } else if (this.hoveredObject != this.currentHoveredObject) {
          // Si el puntero ya no está sobre el objeto, lanzar 'mouseleave'.
          this.handleMouseEvent("mouseleave");
          this.hoveredObject = null;
        }
      }
      if (
        this.currentHoveredObject &&
        this.currentHoveredObject.name === "object_element"
      ) {
        let distance = Player.position.distanceTo(
          this.currentHoveredObject.parent.position
        );
        let selector = document.querySelector(".webgl");
        if (distance < this.raycastDistance) {
          selector.classList.add("no-pointer-events");
        } else {
          selector.classList.remove("no-pointer-events");
        }
      } else {
        selector.classList.remove("no-pointer-events");
      }
    }
  }

  // Función para manejar el evento 'mousedown' en el documento.
  handleMouseDown(event) {
    this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;

    this.raycaster.setFromCamera(this.pointer, Cam.camera);
    this.intersects = this.raycaster.intersectObjects(Scene.children);
    if (this.intersects.length > 0) {
      console.log(this.intersects[0]);
      this.firstHoveredObject = this.intersects[0].object;
    }
  }

  // Función para manejar el evento 'click' en el documento.
  handleMouseClick(event) {
    this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;

    this.raycaster.setFromCamera(this.pointer, Cam.camera);
    this.intersects = this.raycaster.intersectObjects(Scene.children);

    if (this.intersects.length > 0) {
      this.hoveredObject = this.intersects[0].object;
      this.hoveredObject.getWorldPosition(this.objectPos);
      // Calculamos la distancia del player al objeto interactuable
      this.distance = Player.position.distanceTo(this.objectPos);
      if (this.distance < this.raycastDistance) {
        // Verificamos si el objeto sobre el cual se hizo click es el mismo que cuando se hizo mousedown y si hay una función de click
        if (
          this.hoveredObject === this.firstHoveredObject &&
          this.modelEvents[this.hoveredObject.uuid]
        ) {
          this.handleMouseEvent("click");
        }
      }
    }
  }

  handleMouseEvent(eventName) {
    if (
      this.hoveredObject &&
      this.modelEvents[this.hoveredObject.uuid] &&
      this.modelEvents[this.hoveredObject.uuid][eventName]
    ) {
      this.eventFunction = this.modelEvents[this.hoveredObject.uuid][eventName];
      this.eventFunction();
    }
  }

  // Función para agregar una función de evento a un modelo en base a su UUID.
  addEventToModel(modelUuid, eventFunction) {
    this.modelEvents[modelUuid] = eventFunction;
  }

  // Función para eliminar una función de evento de un modelo en base a su UUID.
  removeEventFromModel(modelUuid) {
    if (this.modelEvents[modelUuid]) {
      delete this.modelEvents[modelUuid];
      return true; // Indica que la eliminación fue exitosa
    }
    return false; // Indica que no se encontró un evento con el UUID proporcionado
  }
}
