import * as THREE from 'three'
import Template from "./Joystick.html?raw"
import Component from "../../Utils/Component"
import Endpoints from '../../Services/Endpoints'
import Data from "../../../metaverse.json"
import { Touch } from "../../Functions/Device"

export default class Joystick extends THREE.Vector3{
    constructor(){
        super()
        if(Touch())this.init()
        this.run = false
    }

    init = () => {
        new Component(Template,document.body,'joystick')
        this.component = document.querySelector('.joystick')
        this.joystick = this.component.querySelector(".joystick-thumb")
        this.component.style.background = `url(${Endpoints.PATHS.UI.MAIN}${Data.joystick.background})` 
        this.joystick.style.background = `url(${Endpoints.PATHS.UI.MAIN}${Data.joystick.thumb})`

        this.maxRadius = 40
        this.runTolenrance = 0.7
        this.maxRadiusSquared = this.maxRadius * this.maxRadius
        this.origin = {
            left: this.joystick.offsetLeft,
            top: this.joystick.offsetTop
        }
        this.isMouseDown = false

        if ('ontouchstart' in window) {
            this.joystick.addEventListener('touchstart', this.start)
            this.joystick.addEventListener('touchmove', this.move)
            this.joystick.addEventListener('touchend', this.up)
        } else {
            this.joystick.addEventListener('mousedown', this.start)
            this.joystick.addEventListener('mousemove', this.move)
            this.joystick.addEventListener('mouseup', this.up)
            this.joystick.addEventListener('mouseout', this.up)
        }
    }

    getPosition (event) {
        let clientX = event.targetTouches
          ? event.targetTouches[0].pageX
          : event.clientX
        let clientY = event.targetTouches
          ? event.targetTouches[0].pageY
          : event.clientY
        return { x: clientX, y: clientY }
    }

    start = e => {
        const event = e || window.event
        event.preventDefault()
        this.isMouseDown = true
        this.offset = this.getPosition(event)
    }

    move = e => {
        if (!this.isMouseDown) return

        const event = e || window.event
        event.preventDefault()
        const mouse = this.getPosition(event)
        let left = mouse.x - this.offset.x
        let top = mouse.y - this.offset.y
    
        const sqMag = left * left + top * top
        if (sqMag > this.maxRadiusSquared) {
          const magnitude = Math.sqrt(sqMag)
          left /= magnitude
          top /= magnitude
          left *= this.maxRadius
          top *= this.maxRadius
        }

        this.joystick.style.top = `${top + this.joystick.clientHeight / 2}px`
        this.joystick.style.left = `${left + this.joystick.clientWidth / 2}px`
    
        const x = (left - this.origin.left + this.joystick.clientWidth / 2) / this.maxRadius
        const y = (top - this.origin.top + this.joystick.clientHeight / 2) / this.maxRadius

        if(x>this.runTolenrance||x<-this.runTolenrance||y>this.runTolenrance||y<-this.runTolenrance){
            this.run=true
        }else{
            this.run=false
        }
        this.set(x, y, 0)
        this.normalize()
    }

    up = e => {
        const event = e || window.e
        event.preventDefault()

        this.isMouseDown = false

        this.joystick.style.top = `${this.origin.top}px`
        this.joystick.style.left = `${this.origin.left}px`
        this.set(0, 0, 0)
    }

}

