import * as THREE from "three";
import Data from "./Data/Metaverse.json";
import SocketService from "./Services/Socket";
import Camera from "./Camera";
import EventEmitter from "./Utils/EventEmitter";
import MenuComponent from "./Components/Menu/Menu";
import CookiesService from "./Services/Cookies";
import ChatComponent from "./Components/Chat/Chat";
import ConfiguratorComponent from "./Components/Configurator/Configurator";
import SettingsComponent from "./Components/Settings/Settings";
import Raycaster from "./Utils/Raycaster";

let Canvas, 
    Room, 
    Scene, 
    Player, 
    Socket, 
    Emitter, 
    Cam, 
    Menu, 
    User, 
    Avatar, 
    Wallet, 
    Cookies, 
    Purchased, 
    Chat,
    Configurator,
    Settings,
    Raycast

class Meta{
    constructor(){
        Canvas = document.querySelector("canvas.webgl")
        Room = Data.setup.initialRoom
        Scene = new THREE.Scene
        Player = {
            position : new THREE.Vector3(0, 0, 0),
            rotation : new THREE.Vector3(0, 0, 0)
        }
        Socket = new SocketService(Room)
        Emitter = new EventEmitter()
        Cam = new Camera(Canvas);
        Cookies = new CookiesService()
        Raycast = new Raycaster()
        Wallet = 0
        Purchased = []
    }

    initComps = comps => {
        Menu  = new MenuComponent() 
        comps.chat && (Chat = new ChatComponent('.menu'))
        comps.configurator && (Configurator = new ConfiguratorComponent())
        comps.settings && (Settings = new SettingsComponent())
    }

    setRoom = data => { Room = data }
    setUser = data => { User = data }
    setAvatar = data => { Avatar = data }
    setWallet = data => { Wallet = data }
    setPurchased = data => { Purchased = data }
    setPlayerPosition = data => { Player.position = data }
}

const SceneManager = new Meta()

const InitComponents = data => {return SceneManager.initComps(data)}
const SetRoom = data => {return SceneManager.setRoom(data)}
const SetUser = data => {return SceneManager.setUser(data)}
const SetAvatar = data => {return SceneManager.setAvatar(data)}
const SetWallet = data => {return SceneManager.setWallet(data)}
const SetPurchased = data => {return SceneManager.setPurchased(data)}
const SetPlayerPosition = data => {return SceneManager.setPlayerPosition(data)}

export  {
    Canvas, 
    Room, 
    Scene, 
    Player, 
    Socket, 
    Emitter, 
    Cam, 
    Menu, 
    User, 
    Avatar, 
    Cookies,
    Chat,
    Configurator,
    Settings,
    Raycast,
    SetRoom, 
    SetUser, 
    SetAvatar, 
    SetWallet, 
    SetPlayerPosition, 
    SetPurchased,
    InitComponents
}