import * as dat from 'lil-gui'
import Stats from 'three/examples/jsm/libs/stats.module'

export default class Debug
{
    constructor()
    {
        this.active = window.location.hash === '#debug'
        this.guiCreated = false // Variable para rastrear si ya se creo un GUi
        this.stats = new Stats()

        if(this.active)
        {
            this.ui = new dat.GUI()
            document.body.appendChild(this.stats.dom)
        }
    }


    /**
     * Agregar un tooltip (descripción emergente) a los controles GUI.
     * @param {Object} controller - El control GUI al que se le quiere añadir el tooltip.
     * @param {string} description - El texto que se mostrará en el tooltip.
     */
    addTooltip(controller, description) {
        // Escucha el evento de pasar el mouse por encima del controlador
        controller.domElement.addEventListener('mouseenter', function (event) {
            // Crea un div para el tooltip
            const tooltip = document.createElement('div');
            // Establece el estilo del tooltip
            tooltip.style.position = 'absolute';
            tooltip.style.backgroundColor = 'white'; // Cambia el color de fondo al deseado
            tooltip.style.zIndex = '10000'; // Añade un z-index muy alto
            tooltip.textContent = description; // Añade el texto del tooltip
            tooltip.style.fontSize = '20px'; // Cambia el tamaño de la letra

            // Ajusta la posición del tooltip según la posición del controlador
            const rect = this.getBoundingClientRect(); // Obtiene la posición y tamaño del controlador
            tooltip.style.top = `${rect.top + 200}px`; // Alinea con la parte superior del controlador y desplaza hacia abajo
            tooltip.style.left = `${rect.left - 200}px`; // Se mueve a la izquierda del controlador 

            // Añade el tooltip al documento
            document.body.appendChild(tooltip);

            // Cuando el mouse sale del controlador, se elimina el tooltip
            this.addEventListener('mouseleave', function () {
                document.body.removeChild(tooltip);
            });
        });
    }

    update()
    {
        this.stats.update()
    }

}