
const CoinsTransform = (model, index) => {

    console.log('TRANSFORMER');
    // Hay que optimizar este codigo para que te devuleva los valores globales
    // ahora mismo creo que esta recogiendo los valores del modelo, valores
    // que pueden ser 0,0,0 como su valor globlal, todo depende de su exportado
    if (index < 1 || index > 3) console.error("Index must be between 1 and 3");
    const datosModel = [];

    model.traverse((c) => {
      if (c.isMesh) {
        const position = c.position;
        const quaternion = c.quaternion;
        const scale = c.scale;

        let datos = {};
        switch (index) {
          case 1:
            datos = {
              px: position.x,
              py: position.y,
              pz: position.z,
            };
            break;
          case 2:
            datos = {
              px: position.x,
              py: position.y,
              pz: position.z,
              qw: quaternion._w,
              qx: quaternion._x,
              qy: quaternion._y,
              qz: quaternion._z,
            };
            break;
          case 3:
            datos = {
              px: position.x,
              py: position.y,
              pz: position.z,
              qw: quaternion._w,
              qx: quaternion._x,
              qy: quaternion._y,
              qz: quaternion._z,
              sx: scale.x,
              sy: scale.y,
              sz: scale.z,
            };
        }
        datosModel.push(datos);
      }
    });

    var string = "";
    for (let i = 0; i < datosModel.length; i++) {
      string +=
        datosModel[i].px + "," + datosModel[i].py + "," + datosModel[i].pz;
      if (!(i === datosModel.length - 1)) {
        string += "/";
      }
    }
    console.log(string);
}


export {
  CoinsTransform
}