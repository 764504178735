import * as THREE from "three";
import OutlinerPass from "./RenderPass/OutlinerPass";
import { CSS3DRenderer } from "three/addons/renderers/CSS3DRenderer.js";
import { Canvas, Scene, Cam, Emitter} from "./SceneManager";

export default class Renderer {
  canvas = Canvas
  scene = Scene
  camera = Cam

  constructor(sizes) {
    
    this.sizes = sizes;

    this.setInstace();
    // this.outlinerPass = new OutlinerPass(this.instance, this.camera.camera, this.scene, this.metaverse.debug, this.metaverse)
    // this.composer = this.outlinerPass.postProcessing();
  }

  setInstace() {
    this.css = new CSS3DRenderer();
    this.css.domElement.style.position = "absolute";
    this.css.domElement.style.top = 0;
    document.querySelector("#css").appendChild(this.css.domElement);
    this.css.setSize(this.sizes.width, this.sizes.heigth);
    // Emitter.on("moving", () => {
    //   console.log("Llamadas a la GPU/sec: ", this.instance.info.render.calls);
    //   console.log("Triangulos/sec: ", this.instance.info.render.triangles);
    // });

    // Instanciate the render
    this.instance = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      failIfMajorPerformanceCaveat: true,
    });
    this.instance.physicallyCorrectedLights = true;
    this.instance.useLegacyLights = false;
    this.instance.shadowMap.enabled = true;
    this.instance.shadowMap.type = THREE.PCFSoftShadowMap;
    // this.instance.sortObjects = false
    // this.instance.autoClear = false
    this.instance.setClearColor("#211d20");
    this.instance.setSize(this.sizes.width, this.sizes.heigth);
    this.instance.setPixelRatio(this.sizes.pixelRatio);
  }
  resize() {
    this.instance.setSize(this.sizes.width, this.sizes.heigth);
    this.instance.setPixelRatio(this.sizes.pixelRatio);
    this.css.setSize(this.sizes.width, this.sizes.heigth);
  }
  update() {
    this.instance.render(this.scene, this.camera.camera);
  }
}
